import {
  BinInfoResponse,
  CalculateInstallmentsMX,
  HttpClient,
  StartApplePaySessionRequest,
  StartApplePaySessionResponse,
  TerminalInfoResponse,
  TransactionInfoResponse,
  ApiClient as globalApiClient,
  PayResponse,
} from "@cloudpayments/api-client";

import { IOrderOptions } from "@src/contracts/OrderOptions";
import { DomainConfiguration } from "@src/runtime-configuration/DomainConfiguration";
import { AppClient } from "@src/services/api";
import { CreateAltPayOrderResponse } from "@src/services/api/models/CreateAltPayOrderResponse";
import { CreateAltPayOrderRequest } from "@src/services/api/models/CreateAltPayOrderRequest";

declare global {
  interface Window {
    SSRDATA?: { order?: IOrderOptions; subscription?: IOrderOptions };
  }
}

export class ApiClient {
  apiClient: globalApiClient;
  constructor(
    private readonly httpClient: HttpClient,
    private readonly runtimeConfiguration: DomainConfiguration,
    private readonly orderService: AppClient
  ) {
    this.apiClient = new globalApiClient({
      host: this.runtimeConfiguration.api.cpHost!,
    });
  }

  public async terminalInfo(
    publicId: string,
    paymentUrl: string,
    language: string
  ): Promise<TerminalInfoResponse> {
    return this.apiClient.terminalInfo(publicId, paymentUrl, language);
  }

  public async order(
    publicOrderId: string,
    forceFromApi = false
  ): Promise<IOrderOptions> {
    if (forceFromApi || window.SSRDATA?.order == null) {
      return this.orderService.order.getOrder(publicOrderId);
    }
    return (
      window.SSRDATA?.order ?? this.orderService.order.getOrder(publicOrderId)
    );
  }

  public async subscription(
    publicSubscriptionId: string,
    forceFromApi = false
  ): Promise<IOrderOptions> {
    if (forceFromApi || window.SSRDATA?.subscription == null) {
      return this.orderService.subscription.getSubscription(
        publicSubscriptionId
      );
    }
    return (
      window.SSRDATA?.subscription ??
      this.orderService.subscription.getSubscription(publicSubscriptionId)
    );
  }

  // todo: надо будет наверное убрать и использовать createAltPayTransaction из AltPayApi
  public async createAltPay(
    request: CreateAltPayOrderRequest
  ): Promise<CreateAltPayOrderResponse> {
    return this.orderService.order.createAltPayTransaction(request);
  }

  public async orderPay(
    publicOrderId: string,
    cryptogram: string,
    email: string | null = null,
    saveCard?: boolean,
    term?: number
  ): Promise<PayResponse> {
    return this.orderService.order.postOrderPay({
      publicOrderId,
      cryptogram,
      email,
      saveCard,
      installmentData: term
        ? {
            term: term,
          }
        : undefined,
    });
  }

  public async subscriptionPay(
    subscriptionId: string,
    cryptogram: string,
    email: string | null = null,
    saveCard?: boolean
  ): Promise<PayResponse> {
    return this.orderService.subscription.postSubscriptionPay({
      subscriptionId,
      cryptogram,
      email,
    });
  }

  public async binInfo(
    bin: string,
    IsCheckCard?: boolean,
    TerminalPublicId?: string
  ): Promise<BinInfoResponse> {
    return await this.apiClient.binInfo(
      bin,
      undefined,
      undefined,
      undefined,
      undefined,
      IsCheckCard,
      TerminalPublicId
    );
  }

  public async installmentMxData(
    terminalPublicId: string,
    amount: number
  ): Promise<CalculateInstallmentsMX> {
    return await this.apiClient.calculateInstallmentsMX(
      terminalPublicId,
      amount
    );
  }

  public async transactionInfo(
    transactionId: string
  ): Promise<TransactionInfoResponse> {
    return await this.apiClient.transactionInfo(transactionId);
  }

  public async startApplePaySession(
    request: StartApplePaySessionRequest
  ): Promise<StartApplePaySessionResponse> {
    return await this.httpClient.post<StartApplePaySessionResponse>(
      this.runtimeConfiguration.api.cpHost + "/applepay/startsessioninternal",
      request
    );
  }
}
