/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OrdersAPI_DTO_API_Requests_TemplateRequest } from '../models/OrdersAPI_DTO_API_Requests_TemplateRequest';
import type { OrdersAPI_DTO_API_Requests_TemplatesSearchRequest } from '../models/OrdersAPI_DTO_API_Requests_TemplatesSearchRequest';
import type { OrdersAPI_DTO_API_Responses_TemplateItem } from '../models/OrdersAPI_DTO_API_Responses_TemplateItem';
import type { OrdersAPI_DTO_API_Responses_TemplateResponse } from '../models/OrdersAPI_DTO_API_Responses_TemplateResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class OrderTemplateService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public postTemplates(
        requestBody?: OrdersAPI_DTO_API_Requests_TemplateRequest,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/templates',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                409: `Conflict`,
            },
        });
    }

    /**
     * @param id
     * @returns OrdersAPI_DTO_API_Responses_TemplateResponse Success
     * @throws ApiError
     */
    public getTemplates(
        id: number,
    ): CancelablePromise<OrdersAPI_DTO_API_Responses_TemplateResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/templates/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
            },
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public putTemplates(
        id: number,
        requestBody?: OrdersAPI_DTO_API_Requests_TemplateRequest,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/templates/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                409: `Conflict`,
            },
        });
    }

    /**
     * @param id
     * @returns any Success
     * @throws ApiError
     */
    public deleteTemplates(
        id: number,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/templates/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns OrdersAPI_DTO_API_Responses_TemplateResponse Success
     * @throws ApiError
     */
    public postTemplatesSearch(
        requestBody?: OrdersAPI_DTO_API_Requests_TemplatesSearchRequest,
    ): CancelablePromise<Array<OrdersAPI_DTO_API_Responses_TemplateResponse>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/templates/search',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns OrdersAPI_DTO_API_Responses_TemplateItem Success
     * @throws ApiError
     */
    public postTemplatesList(
        requestBody?: Array<number>,
    ): CancelablePromise<Array<OrdersAPI_DTO_API_Responses_TemplateItem>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/templates/list',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }

}
