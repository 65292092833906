import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c13f1520"),n=n(),_popScopeId(),n)
const _hoisted_1 = { width: "280px" }
const _hoisted_2 = { colspan: "3" }
const _hoisted_3 = { colspan: "1" }
const _hoisted_4 = {
  colspan: "3",
  class: "label border-top"
}
const _hoisted_5 = {
  colspan: "2",
  class: "border-top"
}
const _hoisted_6 = { class: "cost" }
const _hoisted_7 = { class: "amount" }
const _hoisted_8 = { class: "price" }
const _hoisted_9 = { colspan: "3" }
const _hoisted_10 = { colspan: "2" }
const _hoisted_11 = { class: "cost" }
const _hoisted_12 = { class: "amount" }

export function render(_ctx, _cache) {
  const _component_Text = _resolveComponent("Text")
  const _component_Table = _resolveComponent("Table")
  const _component_Card = _resolveComponent("Card")
  const _directive_skeleton = _resolveDirective("skeleton")

  return (_openBlock(), _createBlock(_component_Card, null, {
    header: _withCtx(() => [
      _withDirectives(_createElementVNode("div", null, [
        _createVNode(_component_Text, {
          type: "h3",
          class: "title desktop"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(!_ctx.isCharity ? _ctx.$t('app.items.header.default') : _ctx.$t('app.items.header.charity')), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_Text, {
          type: "h4",
          class: "title mobile"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(!_ctx.isCharity ? _ctx.$t('app.items.header.default') : _ctx.$t('app.items.header.charity')), 1)
          ]),
          _: 1
        })
      ], 512), [
        [_directive_skeleton, _ctx.loader.isShowSkeleton()]
      ])
    ]),
    content: _withCtx(() => [
      _withDirectives(_createVNode(_component_Table, { class: "desktop table-desktop" }, {
        default: _withCtx(() => [
          _createElementVNode("tr", null, [
            _createElementVNode("th", _hoisted_1, [
              _createVNode(_component_Text, { type: "caption" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('app.items.name')), 1)
                ]),
                _: 1
              })
            ]),
            _createElementVNode("th", null, [
              _createVNode(_component_Text, { type: "caption" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('app.items.cost')), 1)
                ]),
                _: 1
              })
            ]),
            _createElementVNode("th", null, [
              _createVNode(_component_Text, { type: "caption" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('app.items.count')), 1)
                ]),
                _: 1
              })
            ]),
            _createElementVNode("th", null, [
              _createVNode(_component_Text, { type: "caption" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('app.items.sum')), 1)
                ]),
                _: 1
              })
            ])
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
            return (_openBlock(), _createElementBlock("tr", { key: index }, [
              _createElementVNode("td", null, _toDisplayString(item.label), 1),
              _createElementVNode("td", null, _toDisplayString(_ctx.getFormattedAmount(item.price)), 1),
              _createElementVNode("td", null, _toDisplayString(item.quantity), 1),
              _createElementVNode("td", null, _toDisplayString(_ctx.getFormattedAmount(item.amount)), 1)
            ]))
          }), 128)),
          _createElementVNode("tr", null, [
            _createElementVNode("td", _hoisted_2, [
              _createVNode(_component_Text, {
                type: "h4",
                class: "title"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('app.items.total')), 1)
                ]),
                _: 1
              })
            ]),
            _createElementVNode("td", _hoisted_3, [
              _createVNode(_component_Text, {
                type: "h4",
                class: "title"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.getFormattedTotalAmount()), 1)
                ]),
                _: 1
              })
            ])
          ])
        ]),
        _: 1
      }, 512), [
        [_directive_skeleton, _ctx.loader.isShowSkeleton()]
      ]),
      _withDirectives(_createVNode(_component_Table, { class: "mobile table-mobile" }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
            return (_openBlock(), _createElementBlock("tr", { key: index }, [
              _createElementVNode("td", _hoisted_4, _toDisplayString(item.label), 1),
              _createElementVNode("td", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.getFormattedAmount(item.amount)), 1),
                  _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.getFormattedAmount(item.price)) + " x " + _toDisplayString(item.quantity), 1)
                ])
              ])
            ]))
          }), 128)),
          _createElementVNode("tr", null, [
            _createElementVNode("td", _hoisted_9, [
              _createVNode(_component_Text, {
                type: "h5",
                class: "title"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('app.items.total')), 1)
                ]),
                _: 1
              })
            ]),
            _createElementVNode("td", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("div", _hoisted_12, [
                  _createVNode(_component_Text, {
                    type: "h5",
                    class: "title"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.getFormattedTotalAmount()), 1)
                    ]),
                    _: 1
                  })
                ])
              ])
            ])
          ])
        ]),
        _: 1
      }, 512), [
        [_directive_skeleton, _ctx.loader.isShowSkeleton()]
      ])
    ]),
    _: 1
  }))
}