import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7d9b5f72"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card-form" }

export function render(_ctx, _cache) {
  const _component_ExternalCardForm = _resolveComponent("ExternalCardForm")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ExternalCardForm, {
      ref: "cardForm",
      showEmail: false,
      currencySymbol: '',
      formattedAmount: _ctx.formattedAmount,
      isCvvRequired: _ctx.state.cvvRequired,
      skipExpiryValidation: _ctx.skipExpiryValidation,
      cardType: _ctx.state.cardType,
      onOnSubmit: _ctx.payByCard,
      onOnChange: _ctx.onChange,
      onOnBankInfoChanged: _cache[0] || (_cache[0] = $event => (_ctx.$emit('onBankInfoChanged', $event))),
      onOnUpdateEmail: _ctx.onUpdateEmail,
      onOnValid: _cache[1] || (_cache[1] = $event => (_ctx.$emit('onValid', $event))),
      configuration: _ctx.config,
      loading: _ctx.loading,
      disabled: _ctx.state.disabled,
      isForeignCardEnabled: _ctx.isForeignCardEnabled,
      "payment-button-translate-text": _ctx.isCharity ? 'components.cardProcess.charity' : 'components.cardProcess.payment',
      direction: _ctx.direction,
      defaultCardData: _ctx.defaultCardData,
      "is-payment-button-hidden": _ctx.isPaymentButtonHidden
    }, null, 8, ["formattedAmount", "isCvvRequired", "skipExpiryValidation", "cardType", "onOnSubmit", "onOnChange", "onOnUpdateEmail", "configuration", "loading", "disabled", "isForeignCardEnabled", "payment-button-translate-text", "direction", "defaultCardData", "is-payment-button-hidden"])
  ]))
}