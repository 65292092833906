<div class="home">
  <OrderHeader
    v-if="subscription"
    :isCharity="isCharity"
    :title="!isCharity ? $t('app.header.title.subscribe') : $t('app.header.title.charity')"
    :successPaymentMethod="successPaymentMethod"
    :order="subscription"
  />
  <PaymentProcess
    v-if="showProcess"
    @onSuccess="onSuccess"
    @onFail="onFail"
    @onChange="onChange"
    :order="subscription"
    :isRenewalOfSubscription="true"
  />
  <OrderContents
    v-if="showContent"
    :order="subscription"
    :isCharity="isCharity"
  />
</div>
