import { Utils } from "@cloudpayments/vue-utils";
import { DomainConfiguration } from "@src/runtime-configuration/DomainConfiguration";

export class GooglePayApi {
  constructor(private readonly runtimeConfiguration: DomainConfiguration) {}
  private static readonly baseRequest = {
    apiVersion: 2,
    apiVersionMinor: 0,
  };

  private static readonly allowedCardNetworks = [
    "AMEX",
    "DISCOVER",
    "INTERAC",
    "JCB",
    "MASTERCARD",
    "VISA",
  ];

  private static readonly allowedCardAuthMethods = [
    "PAN_ONLY",
    "CRYPTOGRAM_3DS",
  ];

  private getTokenizationSpecification = (publicId: string) => {
    return {
      type: "PAYMENT_GATEWAY",
      parameters: {
        gateway: this.runtimeConfiguration.payments.googlePay.settings.gateway,
        gatewayMerchantId: publicId,
      },
    };
  };

  private static readonly baseCardPaymentMethod = {
    type: "CARD",
    parameters: {
      allowedAuthMethods: GooglePayApi.allowedCardAuthMethods,
      allowedCardNetworks: GooglePayApi.allowedCardNetworks,
    },
  };

  private cardPaymentMethod = (publicId: string) => {
    return Object.assign({}, GooglePayApi.baseCardPaymentMethod, {
      tokenizationSpecification: this.getTokenizationSpecification(publicId),
    });
  };

  private static getGoogleIsReadyToPayRequest() {
    return Object.assign({}, this.baseRequest, {
      allowedPaymentMethods: [this.baseCardPaymentMethod],
    });
  }

  private static getGoogleTransactionInfo(amount: number, currency: string) {
    return {
      currencyCode: currency,
      totalPriceStatus: "FINAL",
      totalPrice: amount.toString(),
    };
  }

  public getGooglePaymentDataRequest(
    publicId: string,
    amount: number,
    currency: string,
    allowedAuthMethods: string[],
    requireEmail = false,
    mainWindowHref: string
  ): google.payments.api.PaymentDataRequest {
    const paymentDataRequest: any = Object.assign({}, GooglePayApi.baseRequest);
    paymentDataRequest.allowedPaymentMethods = [
      this.cardPaymentMethod(publicId),
    ];
    paymentDataRequest.allowedPaymentMethods[0].parameters.allowedAuthMethods =
      allowedAuthMethods;
    paymentDataRequest.transactionInfo = GooglePayApi.getGoogleTransactionInfo(
      amount,
      currency
    );
    paymentDataRequest.merchantInfo = {
      merchantId:
        this.runtimeConfiguration.payments.googlePay.settings.merchantId,
      merchantName:
        this.runtimeConfiguration.payments.googlePay.settings.merchantName,
      merchantOrigin: mainWindowHref,
    };
    paymentDataRequest.emailRequired = requireEmail;
    return paymentDataRequest;
  }

  public static readonly isReadyToPayRequest: google.payments.api.IsReadyToPayRequest =
    {
      apiVersion: 2,
      apiVersionMinor: 0,
      allowedPaymentMethods: [
        {
          type: "CARD",
          parameters: {
            allowedAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
            allowedCardNetworks: [
              "AMEX",
              "DISCOVER",
              "INTERAC",
              "JCB",
              "MASTERCARD",
              "VISA",
            ],
          },
        },
      ],
      existingPaymentMethodRequired: true,
    };

  public async getPaymentsClient(): Promise<google.payments.api.PaymentsClient> {
    if (GooglePayApi.paymentsClientInstance != null) {
      return Promise.resolve(GooglePayApi.paymentsClientInstance!);
    }

    await Utils.loadScript("https://pay.google.com/gp/p/js/pay.js");

    GooglePayApi.paymentsClientInstance =
      new google.payments.api.PaymentsClient({
        environment: this.runtimeConfiguration.payments.googlePay
          .environment as google.payments.api.Environment,
      });

    return Promise.resolve(GooglePayApi.paymentsClientInstance);
  }

  private static paymentsClientInstance:
    | google.payments.api.PaymentsClient
    | undefined;

  public async isGooglePayAvailableOld(): Promise<boolean> {
    const paymentsClient = await this.getPaymentsClient();
    return (
      await paymentsClient!.isReadyToPay(GooglePayApi.isReadyToPayRequest)
    ).result;
  }

  public async isGooglePayAvailable(): Promise<boolean> {
    const paymentsClient: Promise<boolean> = this.isGooglePayAvailableOld();

    const timeoutPromise: Promise<boolean> = new Promise((res) =>
      setTimeout(() => res(false), 3000)
    );

    return Promise.race([paymentsClient, timeoutPromise]);
  }
}
