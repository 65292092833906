import { Utils } from "@cloudpayments/vue-utils";
import { ExternalPaymentMethods } from "@cloudpayments/api-client";
import { ExternalPaymentMethod } from "@cloudpayments/api-client/src/contracts/response/TerminalInfoResponse";
import { TinkoffRequest } from "@cloudpayments/vue-utils";
import { TerminalInfo } from "@src/contracts/TerminalInfo";

declare let tinkoff: any;

export interface TinkoffResponse {
  meta: { iframe: any };
  payload: { [param: string]: string };
  type: string;
}

export class TinkoffInstallmentApi {
  public static sessionInstance: any;

  public static async isPayAvailable(): Promise<boolean> {
    try {
      await TinkoffInstallmentApi.loadLib();
    } catch {
      return Promise.resolve(false);
    }
    return TinkoffInstallmentApi.createSession();
  }

  public static createRequest(): Promise<boolean> {
    return new Promise((success) => {
      success(true);
    });
  }

  public static createTinkoffInstallmentRequest(
    paymentOptions: any,
    terminalInfo: TerminalInfo,
    promoCode: string,
    isCredit = false
  ): TinkoffRequest | null {
    let externalPaymentMethod: ExternalPaymentMethod | undefined = undefined;

    if (isCredit) {
      externalPaymentMethod = terminalInfo.ExternalPaymentMethods.find(
        (item: ExternalPaymentMethod) =>
          item.Type === ExternalPaymentMethods.Loan
      );
    } else {
      externalPaymentMethod = terminalInfo.ExternalPaymentMethods.find(
        (item: ExternalPaymentMethod) =>
          item.Type === ExternalPaymentMethods.TinkoffInstallmentPay
      );
    }

    if (!externalPaymentMethod) {
      return null;
    }

    return {
      integrationType: "cloudPayments",
      sum: paymentOptions?.amount,
      items: [
        {
          name: paymentOptions?.description || "",
          price: paymentOptions?.amount,
          quantity: 1,
        },
      ],
      orderNumber: paymentOptions.orderNumber,
      promoCode: promoCode,
      shopId: externalPaymentMethod.ShopId,
      values: {
        contact: {
          email: paymentOptions?.email,
        },
      },
      showcaseId: externalPaymentMethod.ShowCaseId,
    } as any; //todo: fix type in vue-utils
  }

  public static async getSession(): Promise<any> {
    return TinkoffInstallmentApi.sessionInstance;
  }

  public static async createSession(): Promise<any> {
    if (TinkoffInstallmentApi.sessionInstance != null) {
      return Promise.resolve(TinkoffInstallmentApi.sessionInstance!);
    }

    if (!tinkoff) {
      return Promise.resolve(false);
    }

    TinkoffInstallmentApi.sessionInstance = tinkoff;

    return Promise.resolve(TinkoffInstallmentApi.sessionInstance);
  }

  public static async loadLib(): Promise<void> {
    if ("tinkoff" in window) {
      return Promise.resolve();
    }

    return Utils.loadScript("https://forma.tinkoff.ru/static/onlineScript.js");
  }
}
