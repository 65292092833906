import { computed, nextTick, ref } from "vue";
import { translate } from "@cloudpayments/vue-utils";
import { DomainConfiguration } from "@src/runtime-configuration/DomainConfiguration";
import { supportedLang } from "@src/runtime-configuration/SupportedLang";
import { Lang } from "@src/contracts/LangEnum";

export type LangArgs = { [param: string]: string };

export type LangCollection = { [param: string]: string | LangCollection };

let collection: LangCollection = {};

export let messagesPromise: Promise<any>;

let currentLanguage: Lang;
export async function loadLanguage(localeLang: Lang, defaultLang: Lang) {
  return new Promise<void>((resolve) => {
    import(`./language/${localeLang}.json`).then(
      () => {
        currentLanguage = localeLang;
        messagesPromise = import(
          /* webpackChunkName: "language-[request]" */
          /* webpackPrefetch: false */
          `./language/${localeLang}.json`
        );

        messagesPromise.then((collectionMessages) => {
          collection = collectionMessages;
          resolve();
        });
      },
      () => {
        messagesPromise = import(`./language/${defaultLang}.json`);
        messagesPromise.then((collectionMessages) => {
          collection = collectionMessages;
          resolve();
        });
      }
    );
  });
}

export async function loadLocaleMessages(): Promise<void> {
  collection = await messagesPromise;
  return nextTick();
}

export const langPlugin = {
  install: async (app: any, domainConfig: DomainConfiguration) => {
    app.config.globalProperties.locale = ref();
    app.config.globalProperties.$t = (key: string, args?: LangArgs) => {
      return computed(() => {
        switch (app.config.globalProperties.locale.value) {
          default:
            return translate(key, args, collection, pluralizeStr);
        }
      }).value;
    };
    app.config.globalProperties.changeLocale = async (cultureName: string) => {
      let locale: Lang = cultureName.substring(0, 2) as Lang;

      if (!supportedLang.includes(locale)) {
        locale = domainConfig.defaultLanguage;
      }

      await loadLanguage(locale, domainConfig.defaultLanguage);
      await loadLocaleMessages();
      app.config.globalProperties.locale.value = locale;
      return nextTick();
    };
  },
};

export function pluralizeStr(str: string, count: number): string {
  const strParts = str.split(" | ");
  if (strParts?.length) {
    switch (currentLanguage) {
      case Lang.Ru: {
        const isTeenNumbers = count > 10 && count < 20;
        const manyStr = strParts[2] || strParts[1] || strParts[0];
        if (!isTeenNumbers) {
          return count % 10 === 1
            ? strParts[0]
            : [2, 3, 4].includes(count % 10)
            ? strParts[1] || strParts[0]
            : manyStr;
        } else {
          return manyStr;
        }
      }
      case Lang.En: {
        return count === 1 ? strParts[0] : strParts[1] || strParts[0];
      }
    }
    return strParts[0];
  }
  return "";
}
