export class WordsHelper {
  public static declOfNum(insertNum: number, textForms: string[]): string {
    const num = Math.abs(insertNum) % 100;
    const numPercent = num % 10;
    if (num > 10 && num < 20) {
      return textForms[2];
    }
    if (numPercent > 1 && numPercent < 5) {
      return textForms[1];
    }
    if (numPercent === 1) {
      return textForms[0];
    }
    return textForms[2];
  }
}
