<div class="wait">
    <LoaderIcon class="loading-icon" type="medium"/>
    <div class="header">
        <span v-if="!isSom">{{ $t('modals.waitBottomSheet.waitingResponse') }}</span>
        <span v-if="isSom">{{ $t('modals.waitBottomSheet.waitingResponseBank') }}</span>
        <Icon height="26" width="70" v-if="icon"><component :is="icon"></component></Icon></div>
    <Text type="p3" class="info"><span v-if="!isSom">{{ $t('modals.waitBottomSheet.info') }}</span><span v-if="isSom">{{ $t('modals.waitBottomSheet.info2') }}</span></Text>
    <Button class="return-button" type="secondary" @click="backToPaymentMethods()">{{ $t('modals.waitBottomSheet.anotherMethod') }}</Button>
    <Button v-if="reloadStatus" type="tertiary" @click="onReloadStatus"><ReloadIcon/><span class="status-text">{{ $t('modals.waitBottomSheet.refreshStatus') }}</span></Button>
</div>
