export const environment = {
  configPath: "/feature-api/config",
  orders: {
    baseUrl: window.location.origin,
    indexPage: "/",
    notSupportedUrl: "/not_supported.html",
    resultPage: "/result.html",
  },
  common: {
    postMessageSignature: "d4c195b4-d0cc-4b60-b123-2f9743420d67",
  },
  api: {
    endpoints: {
      threeDsCallback: "/Payments/ThreeDsCallback",
    },
  },
  debug: {
    logging: false,
  },
  sbp: {
    nspkUrl: "https://qr.nspk.ru/",
    banksPath: "proxyapp/c2bmembers.json",
  },
};
