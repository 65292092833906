import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7e991313"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "home" }

export function render(_ctx, _cache) {
  const _component_OrderHeader = _resolveComponent("OrderHeader")
  const _component_PaymentProcess = _resolveComponent("PaymentProcess")
  const _component_OrderContents = _resolveComponent("OrderContents")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.subscription)
      ? (_openBlock(), _createBlock(_component_OrderHeader, {
          key: 0,
          isCharity: _ctx.isCharity,
          title: !_ctx.isCharity ? _ctx.$t('app.header.title.subscribe') : _ctx.$t('app.header.title.charity'),
          successPaymentMethod: _ctx.successPaymentMethod,
          order: _ctx.subscription
        }, null, 8, ["isCharity", "title", "successPaymentMethod", "order"]))
      : _createCommentVNode("", true),
    (_ctx.showProcess)
      ? (_openBlock(), _createBlock(_component_PaymentProcess, {
          key: 1,
          onOnSuccess: _ctx.onSuccess,
          onOnFail: _ctx.onFail,
          onOnChange: _ctx.onChange,
          order: _ctx.subscription,
          isRenewalOfSubscription: true
        }, null, 8, ["onOnSuccess", "onOnFail", "onOnChange", "order"]))
      : _createCommentVNode("", true),
    (_ctx.showContent)
      ? (_openBlock(), _createBlock(_component_OrderContents, {
          key: 2,
          order: _ctx.subscription,
          isCharity: _ctx.isCharity
        }, null, 8, ["order", "isCharity"]))
      : _createCommentVNode("", true)
  ]))
}