import { CardData } from "@cloudpayments/vue-ui-presets";

export class CardDataService {
  private _cardData: CardData;

  constructor() {
    this._cardData = {
      cardNumber: "",
      cardExpiration: "",
      cardSecretCode: "",
    };
  }

  get cardData() {
    return this._cardData;
  }

  set cardData(cardData: CardData) {
    this._cardData = {
      ...this._cardData,
      cardNumber: cardData.cardNumber,
      cardExpiration: cardData.cardExpiration,
      cardSecretCode: cardData.cardSecretCode,
    };
  }
}
