import {
  AppClient,
  OrdersAPI_DTO_API_Requests_GetQrStatusRequest,
  OrdersAPI_DTO_API_Requests_QrPayRequest,
} from "@src/services/api";
import {
  QRImageResponse,
  QRLinkResponse,
  QRResponse,
  QRStatusCode,
  QRStatusResponse,
} from "@cloudpayments/api-client";
import { DomainConfiguration } from "@src/runtime-configuration/DomainConfiguration";

export enum SbpResultStatus {
  Wait = "wait",
  AwaitingAuthentication = "awaitingAuthentication",
  Success = "success",
  Canceled = "canceled",
  Declined = "declined",
  Fail = "fail",
}
export class SbpApi {
  private readonly appClient: AppClient;
  constructor(public readonly runtimeConfiguration: DomainConfiguration) {
    this.appClient = new AppClient({
      BASE: this.runtimeConfiguration.api.host,
    });
  }

  public async createSBPQRImage(
    request: OrdersAPI_DTO_API_Requests_QrPayRequest
  ): Promise<QRResponse<QRImageResponse>> {
    return this.appClient.order.postOrderSbpQrImage(request);
  }

  public async createSBPLink(
    request: OrdersAPI_DTO_API_Requests_QrPayRequest
  ): Promise<QRResponse<QRLinkResponse>> {
    return this.appClient.order.postOrderSbpQrLink(request);
  }

  public async getQrStatus(
    request: OrdersAPI_DTO_API_Requests_GetQrStatusRequest
  ): Promise<QRResponse<QRStatusResponse>> {
    return this.appClient.order.postOrderGetQrStatus(request);
  }

  public async waitSBPStatus(
    request: OrdersAPI_DTO_API_Requests_GetQrStatusRequest
  ): Promise<SbpResultStatus> {
    const waitSBPStatusResponse =
      await this.appClient.order.postOrderWaitQrStatus(request);

    if (
      waitSBPStatusResponse &&
      waitSBPStatusResponse.Success &&
      waitSBPStatusResponse.Model &&
      Object.prototype.hasOwnProperty.call(
        waitSBPStatusResponse.Model,
        "StatusCode"
      )
    ) {
      switch (waitSBPStatusResponse.Model.StatusCode) {
        case QRStatusCode.Created:
        case QRStatusCode.Pending:
          return SbpResultStatus.Wait;
        case QRStatusCode.AwaitingAuthentication:
          return SbpResultStatus.AwaitingAuthentication;
        case QRStatusCode.Authorized:
        case QRStatusCode.Completed:
          return SbpResultStatus.Success;
        case QRStatusCode.Cancelled:
          return SbpResultStatus.Canceled;
        case QRStatusCode.Declined:
          return SbpResultStatus.Declined;
        default:
          return SbpResultStatus.Fail;
      }
    } else {
      return SbpResultStatus.Fail;
    }
  }
}
