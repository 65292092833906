<Card v-show="!isSpei" direction="vertical">
    <template #header>
        <div class="payment-process__header">
            <div class="payment-process__content-header-title" v-skeleton="loader.isShowSkeleton()">
                <Text type="h3" class="title desktop">
                    <template v-if="!['card', 'cash'].includes(state.paymentMethod)">
                      {{ $t("app.process.payment.methods") }}
                    </template>
                    <template v-if="state.paymentMethod === 'card' && !isInstallmentCard">
                      {{ $t("app.process.payment.card") }}
                    </template>
                    <template v-if="state.paymentMethod === 'card' && isInstallmentCard">
                        {{ $t("app.process.payment.installment") }}
                    </template>
                    <template v-if="state.paymentMethod === 'cash' && !selectedCashMethod">
                      {{ $t("components.cash.payInCash") }}
                    </template>
                </Text>
              <Text type="h4" class="title mobile">
                <template v-if="!['card', 'cash'].includes(state.paymentMethod)">
                  {{ $t("app.process.payment.methods") }}
                </template>
                <template v-if="state.paymentMethod === 'card' && !isInstallmentCard">
                  {{ $t("app.process.payment.card") }}
                </template>
                <template v-if="state.paymentMethod === 'card' && !isInstallmentCard">
                    {{ $t("app.process.payment.installment") }}
                </template>
                <template v-if="state.paymentMethod === 'cash' && !selectedCashMethod">
                  {{ $t("components.cash.payInCash") }}
                </template>
              </Text>
            </div>
            <div class="payment-process__header-action" :style="state.paymentMethod === 'cash' ? {color: '#00B3ED'} : {}" v-skeleton="loader.isShowSkeleton()" v-if="state.paymentMethod && showMoreMethodsButton" @click="onChangePaymentMethod(null)">
              {{ $t(state.paymentMethod === 'cash' ? "components.cash.otherMethods" : "app.process.payment.other") }}
            </div>
        </div>
    </template>
    <template #content>
      <Process ref="process">
        <Step name="result">
          <div class="payment-process__content">
            <div class="payment-process__result">
              <Alert type="error">
                  <template #content>
                      <span>{{ !$t(`errors.${state.reasonCode}`) ? $t('app.process.payment.error', { method: state.paymentMethod === 'card' ? $t(`app.header.methods.card`) : '' }) : $t(`errors.${state.reasonCode}`) }}</span>
                      <span v-if="state.reasonCode === 6004 && locale !== 'kk-KZ'"> {{ ' ' + currencySymbol }} </span>
                  </template>
              </Alert>
            </div>
          </div>
        </Step>
        <Step name="form">
          <div class="payment-process__content">
            <div class="payment-process__methods">
              <div class="actions"
                :class="{
                  'actions-small-buttons': !som.isAvailable && !cardInstallment.isAvailable,
                  'columns-2': availablePayCount === 2,
                  'columns-3': ((som.isAvailable || cardInstallment.isAvailable) && (availablePayCount > 2)) || (!som.isAvailable && ([3,5,6,9].includes(availablePayCount))),
                  'columns-4': !som.isAvailable && !cardInstallment.isAvailable && ([4,7,8].includes(availablePayCount) || availablePayCount > 9)
                }"
              >
                <div class="button">
                  <div class="pay-button" v-skeleton="loader.isShowSkeleton()">
                    <Button
                      :disabled="showEmailField && !receiptEmail"
                      type="primary" @click="onChangePaymentMethod('card')" :loading="cardPay.isLoading">
                      {{ $t("app.process.method.card") }}
                    </Button>
                  </div>
                </div>
                <div class="button" v-if="som.isAvailable">
                    <div class="pay-button" v-skeleton="loader.isShowSkeleton()">
                        <Button
                                :disabled="showEmailField && !receiptEmail"
                                type="primary" @click="payBySom()" :loading="cardPay.isLoading">
                                <div style="margin-right: 10px">
                                    {{ $t("app.process.method.foreignCard") }}
                                </div>
                        </Button>
                    </div>
                </div>
                <div class="button" v-if="cardInstallment.isAvailable">
                    <div class="pay-button" :class="{ disabled: (showEmailField && !receiptEmail) }" v-skeleton="loader.isShowSkeleton()">
                        <Button class="card-installment-button" @click="goToCardInstallment()" :loading="cardInstallment.isLoading" :disabled="cardInstallment.isLoading">
                            <span>{{ $t("app.process.method.cardInstallment") }}</span>
                        </Button>
                    </div>
                </div>
                <div class="button" v-if="spei.isAvailable">
                    <div class="pay-button" :class="{ disabled: ((showEmailField && !receiptEmail) || spei.isLoading) }" v-skeleton="loader.isShowSkeleton()">
                        <Button class="spei-button" @click="payBySpei()" :loading="spei.isLoading" :disabled="spei.isLoading">
                            <Icon width="54" height="16">
                                <SpeiIcon />
                            </Icon>
                        </Button>
                    </div>
                </div>
                <div class="button" v-if="cash.isAvailable">
                    <div class="pay-button" :class="{ disabled: ((showEmailField && !receiptEmail) || cash.isLoading || !isCashSumAvailable) }" v-skeleton="loader.isShowSkeleton()">
                        <Button class="cash-button" type="secondary" @click="selectCashPayment()" :loading="cash.isLoading" :disabled="cash.isLoading || !isCashSumAvailable">
                            <Icon width="59" height="27">
                                <OxxoLogo />
                            </Icon>
                            <Icon width="22" height="27">
                                <SevenElevenLogo />
                            </Icon>
                            <Icon width="27" height="27">
                                <WallmartLogo />
                            </Icon>
                            <div style="margin-right: -4px;">+ 10</div>
                            <div> {{ $t("components.cash.stores") }} </div>
                        </Button>
                    </div>
                </div>
                <div class="button" v-if="tinkoffPay.isAvailable">
                  <div class="pay-button" :class="{ disabled: showEmailField && !receiptEmail }" v-skeleton="loader.isShowSkeleton()">
                      <Button class="tcs-pay" type="pay" @click="payByTinkoffPay()" :loading="tinkoffPay.isLoading">
                          <Icon width="74px" height="12px">
                              <TinkoffPayLabel />
                          </Icon>
                          <Icon width="50" height="21" style="margin-left: 7px">
                              <TinkoffPayIcon />
                          </Icon>
                      </Button>
                  </div>
                </div>
                  <div class="button" v-if="sberPay.isAvailable">
                      <div class="pay-button" :class="{ disabled: showEmailField && !receiptEmail }" v-skeleton="loader.isShowSkeleton()">
                          <Button type="pay" class="sber-pay" @click="payBySberPay()" :loading="sberPay.isLoading">
                              <Icon width="70" height="24">
                                  <SberPayIcon />
                              </Icon>
                          </Button>
                      </div>
                  </div>
                <div class="button" v-if="dolyame.isAvailable">
                  <div class="pay-button" :class="{ disabled: showEmailField && !receiptEmail }" v-skeleton="loader.isShowSkeleton()">
                      <Button type="pay" @click="payByDolyame()" :loading="dolyame.isLoading">
                        <div class="pay-button__text">
                          {{ $t("components.cardProcess.payment") }}
                        </div>
                        <Icon width="102" height="15">
                          <DolyameIcon />
                        </Icon>
                      </Button>
                  </div>
                </div>
                <div class="button" v-if="yandexPay.isAvailable">
                  <div class="pay-button"
                       :class="{ disabled: showEmailField && !receiptEmail }"
                       v-if="yandexPay.isLoading">
                    <Button
                      :disabled="showEmailField && !receiptEmail"
                      type="pay" :loading="true">
                      <Icon width="21" height="21">
                        <YandexPayIcon />
                      </Icon>
                    </Button>
                  </div>
                  <div class="pay-button yandex"
                       :class="{ disabled: showEmailField && !receiptEmail }"
                       ref="yandexPayButton" v-show="!yandexPay.isLoading"  v-skeleton="loader.isShowSkeleton()"></div>
                </div>
                <div class="button" v-if="applePay.isAvailable">
                  <div class="pay-button" :class="{ disabled: showEmailField && !receiptEmail }" v-skeleton="loader.isShowSkeleton()">
                    <Button
                      :disabled="showEmailField && !receiptEmail"
                      type="pay" @click="payByApplePay()" :loading="applePay.isLoading">
                      <Icon width="49" height="21">
                        <AppleIcon />
                      </Icon>
                    </Button>
                  </div>
                </div>
                <div class="button" v-if="googlePay.isAvailable">
                  <div class="pay-button"
                       :class="{ disabled: showEmailField && !receiptEmail }"
                       v-skeleton="loader.isShowSkeleton()">
                    <Button
                      :disabled="showEmailField && !receiptEmail"
                      type="pay" @click="payByGooglePay()" :loading="googlePay.isLoading">
                      <Icon width="49" height="21">
                        <GoogleIcon />
                      </Icon>
                    </Button>
                  </div>
                </div>
                <div class="button" v-if="tinkoffInstallment.isAvailable" >
                  <div class="pay-button"
                       :class="{ disabled: showEmailField && !receiptEmail }"
                       v-skeleton="loader.isShowSkeleton()">
                    <Button
                            :disabled="showEmailField && !receiptEmail"
                            type="warning" @click="startTinkoffInstallmentProcess()" :loading="tinkoffInstallment.isLoading">
                      {{ $t('components.cardProcess.tinkoffPayment') }}
                    </Button>
                  </div>
                </div>
                <div class="button" v-if="tinkoffCredit.isAvailable">
                  <div class="pay-button" :class="{ disabled: showEmailField && !receiptEmail }" v-skeleton="loader.isShowSkeleton()">
                      <Button class="tinkoff-credit-button" type="pay" @click="payByTinkoffCredit('default', true)" :loading="tinkoffCredit.isLoading">
                          <Icon width="24" height="24">
                              <TinkoffCreditIcon />
                          </Icon>
                          <span class="tinkoff-credit-text">{{ $t('components.cardProcess.tinkoffCredit') }}</span>
                      </Button>
                  </div>
                </div>
                <div class="button" v-if="sbp.isAvailable">
                    <div class="pay-button" :class="{ disabled: showEmailField && !receiptEmail }" v-skeleton="loader.isShowSkeleton()" @click="sendStatistSbpPay()">
                        <SbpProcess @onQrPay="onQrPay" @onResult="sbpPayResponse" />
                    </div>
                </div>
              </div>
              <div v-if="cash.isAvailable && !isCashSumAvailable" class="cash-note cash-note__small">
                {{ $t("components.cash.noteMinAmount") }} {{ getFormattedAmount(cashSettings.MinAmount || 0) }}
              </div>
            </div>
            <div class="show-save-card" v-skeleton="loader.isShowSkeleton()" v-if="shouldShowSaveCard">
              <Checkbox :checked="saveCardObject.saveCard" @onChecked="onSaveCard" name="save-card" class="show-save-card__checkbox"
                  :title="$t('components.cardForm.saveCardCheckboxLabel')" />
              <Icon width="24" height="24" class="show-save-card__icon"
                  @mouseleave.prevent="onShowSaveCardHint(false)" @mouseenter.prevent="onShowSaveCardHint(true)">
                  <QuestionIcon />
              </Icon>
              <Tooltip position="bottom" v-if="showSaveCardTooltip">
                  <div class="save-card-information">
                      <Text type="caption">{{ $t('components.cardForm.saveCardInfo.item1') }}</Text>
                  </div>
              </Tooltip>
            </div>
            <div class="payment-process__show-receipt" v-skeleton="loader.isShowSkeleton()">
              <Checkbox :checked="showEmailField" @onChecked="onShowEmail" name="success-email"
                        :title="$t('components.cardForm.emailCheckboxLabel' + (isMxTranslation ? 'MX' : ''))" />
            </div>
            <div class="payment-receipt" v-if="showEmailField && state.paymentMethod !== 'result'" v-skeleton="loader.isShowSkeleton()">
              <Receipt @onReceipt="onReceipt" :email="receiptEmail" :errorLabelText="$t('components.cardForm.emailLabelError')"/>
                <div class="email-description">
                    <Icon width="16" height="16">
                        <AttentionIcon/>
                    </Icon>
                    <span>{{ $t("components.cardForm.emailInputDescription") }}</span>
                </div>
            </div>
            <div class="show-force-save-card" v-skeleton="loader.isShowSkeleton()" v-if="shouldShowForceSaveHint">
              <Text type="caption">{{ $t('components.cardForm.forceSaveCardInfo') }}</Text>
              <Icon width="24" height="24" class="question-icon" @mouseleave.prevent="onShowForceSaveCardHint(false)"
                  @mouseenter.prevent="onShowForceSaveCardHint(true)">
                  <QuestionIcon />
              </Icon>
              <Tooltip position="bottom" v-if="showForceSaveCardTooltip">
                  <div class="save-card-information">
                      <Text type="caption">{{ $t('components.cardForm.forceSaveCardInfoTooltip') }}</Text>
                  </div>
              </Tooltip>
            </div>
            <div class="payment-process__offer" v-if="offerLink || staticOfferLink || staticAgreementLink" v-skeleton="loader.isShowSkeleton()">
              <Text type="caption" class="inline-offer">
                {{ $t("app.process.offer.text") }}
                <template v-if="offerLink"><a class="link" target="_blank" :href="offerLink">{{
                    $t("app.process.offer.link") }}</a>
                </template>
                <template v-if="staticOfferLink && offerLink">{{ $t("app.process.staticOffer.before") }}</template>
                <template v-if="staticOfferLink">
                  <a class="link link_secondary" target="_blank"
                    :href="staticOfferLink">{{ $t("app.process.staticOffer.link") }}</a>{{
                  $t("app.process.staticOffer.after")
                  }}
                </template>
                <template v-if="staticAgreementLink">
                  {{
                  $t("app.process.staticAgreement.before") }}<a class="link link_secondary" target="_blank"
                    :href="staticAgreementLink">{{ $t("app.process.staticAgreement.link") }}</a>{{
                  $t("app.process.staticAgreement.after") }}
                </template>
              </Text>
            </div>
            <div class="payment-process__offer" v-if="!staticAgreementLink && agreementLink">
              <Text type="caption" class="inline-offer">
                {{ $t("app.process.confirm.text") }} <a class="link" target="_blank" :href="agreementLink">{{ $t("app.process.confirm.link") }}</a>
              </Text>
            </div>
            <div class="payment-process__privacy-police" v-skeleton="loader.isShowSkeleton()" v-if="privacyPoliceLink">
                 <Text type="caption" class="inline-offer">
                     {{ $t("app.process.privacyPolice.text") }} <a class="link" target="_blank" :href="privacyPoliceLink">{{ $t("app.process.privacyPolice.link") }}</a>
                 </Text>
            </div>
          </div>
        </Step>
        <Step name="payment">
          <div class="payment-process__content" v-skeleton="loader.isShowSkeleton()">
            <div class="alert" style="padding-bottom: 16px"
                v-if="som.isAvailable && !isMirCard && isCardNumberCountryChanged && (cardNumberCountry !== 643) && cardNumber && cardNumber !== 'null'"
            >
              <Alert type="error">
                <template #content>
                  {{ $t("components.cardForm.cardNumberNotRuAlertNew") }}
                </template>
              </Alert>
            </div>
            <div class="payment-process__process">
              <CardForm
                class="payment-process__card-form-wrapper"
                ref="cardForm"
                :disabled="(showEmailField && !receiptEmail) ||
                (som.isAvailable && !isMirCard && isCardNumberCountryChanged && (cardNumberCountry !== 643) && cardNumber && cardNumber !== 'null')"
                :currency="currency"
                :amount="amount"
                :loading="cardPay.isLoading"
                :isCvvRequired="isCvvRequired"
                :skipExpiryValidation="skipExpiryValidation"
                :isPaymentButtonHidden="isInstallmentCard"
                :isNeedValidationBE="isInstallmentCard"
                @onValid="onValidMX"
                @onChangeBinInfo="changeBinInfoMX"
                :isForeignCardEnabled="som.isAvailable && !isMirCard"
                :default-card-data="defaultCardData"
                @onChange="onChange"
                @onBankInfoChanged="onBankInfoChanged"
                @onPay="payByCard" v-if="state.paymentMethod === 'card'">
              </CardForm>
              <div class="button payment-process__installment-button-wrapper-desktop" v-if="isInstallmentCard">
                  <div class="pay-button" :class="{
                    disabled: !formInstallmentMX.period
                    || !formInstallmentMX.isCardDataValid
                    || !formInstallmentMX.isBankDataAvailable
                    || cardInstallment.isLoading
                    }" v-skeleton="loader.isShowSkeleton()">
                      <Button class="card-installment-button" @click="payByInstallment()"
                              :loading="cardInstallment.isLoading"
                              :disabled="!formInstallmentMX.period
                    || !formInstallmentMX.isCardDataValid
                    || !formInstallmentMX.isBankDataAvailable
                    || cardInstallment.isLoading">
                          <span>{{ $t("app.process.method.cardInstallment") }}</span>
                      </Button>
                  </div>
              </div>
            </div>
            <Alert type="error" class="payment-process__installment-alert" v-if="isInstallmentAlert">
                <template #content>{{ $t("app.process.cardInstallment.alertText") }}</template>
            </Alert>
            <div class="payment-process__select-period" v-if="periods?.length && isInstallmentCard">
                <div class="payment-process__select-title">{{ $t("app.process.cardInstallment.selectTitle") }}</div>
                <SelectV2 :list="periods" :label="$t('app.process.cardInstallment.label')" v-model="formInstallmentMX.period">
                    <template v-slot:item="{ option, handleClick }">
                        <div class="payment-process__period-item">
                            {{ option?.name }}
                        </div>
                    </template>
                    <template v-slot:selected="{ selected, toggle }">
                        <div class="payment-process__selected-period-item" @click="toggle()">
                            {{ selected?.name }}
                        </div>
                    </template>
                </SelectV2>
            </div>
            <div class="button payment-process__installment-button-wrapper-mobile" v-if="isInstallmentCard">
                <div class="pay-button" :class="{
                    disabled: !formInstallmentMX.period
                    || !formInstallmentMX.isCardDataValid
                    || !formInstallmentMX.isBankDataAvailable
                }" v-skeleton="loader.isShowSkeleton()">
                    <Button class="card-installment-button" @click="payByInstallment"
                            :loading="cardInstallment.isLoading"
                            :disabled="!formInstallmentMX.period
                    || !formInstallmentMX.isCardDataValid
                    || !formInstallmentMX.isBankDataAvailable
                    || cardInstallment.isLoading">
                        <span>{{ $t("app.process.method.cardInstallment") }}</span>
                    </Button>
                </div>
            </div>
            <div class="show-save-card" v-skeleton="loader.isShowSkeleton()" v-if="shouldShowSaveCard">
              <Checkbox :checked="saveCardObject.saveCard" @onChecked="onSaveCard" name="save-card" class="show-save-card__checkbox"
                  :title="$t('components.cardForm.saveCardCheckboxLabel')" />
              <Icon width="24" height="24" class="show-save-card__icon"
                  @mouseleave.prevent="onShowSaveCardHint(false)" @mouseenter.prevent="onShowSaveCardHint(true)">
                  <QuestionIcon />
              </Icon>
              <Tooltip position="bottom" v-if="showSaveCardTooltip">
                  <div class="save-card-information">
                      <Text type="caption">{{ $t('components.cardForm.saveCardInfo.item1') }}</Text>
                  </div>
              </Tooltip>
            </div>
            <div class="show-force-save-card" v-skeleton="loader.isShowSkeleton()" v-if="shouldShowForceSaveHint">
              <Text type="caption">{{ $t('components.cardForm.forceSaveCardInfo') }}</Text>
              <Icon width="24" height="24" class="question-icon" @mouseleave.prevent="onShowForceSaveCardHint(false)"
                  @mouseenter.prevent="onShowForceSaveCardHint(true)">
                  <QuestionIcon />
              </Icon>
              <Tooltip position="bottom" v-if="showForceSaveCardTooltip">
                  <div class="save-card-information">
                      <Text type="caption">{{ $t('components.cardForm.forceSaveCardInfoTooltip') }}</Text>
                  </div>
              </Tooltip>
            </div>
            <div v-if="showCheckboxesCardPayment()">
              <div class="payment-process__show-receipt" v-skeleton="loader.isShowSkeleton()">
                <Checkbox :checked="showEmailField" @onChecked="onShowEmail" name="success-email"
                          :title="$t('components.cardForm.emailCheckboxLabel' + (isMxTranslation ? 'MX' : ''))" />
              </div>
              <div class="payment-process__privacy-police" v-skeleton="loader.isShowSkeleton()" v-if="privacyPoliceLink">
                {{ $t("app.process.privacyPolice.text") }} <a class="link" target="_blank" :href="privacyPoliceLink">{{ $t("app.process.privacyPolice.link") }}</a>
              </div>
              <div class="payment-receipt" v-if="showEmailField && state.paymentMethod !== 'result'" v-skeleton="loader.isShowSkeleton()">
                <Receipt @onReceipt="onReceipt" :email="receiptEmail" :errorLabelText="$t('components.cardForm.emailLabelError')"/>
                  <div class="email-description">
                      <Icon width="16" height="16">
                          <AttentionIcon/>
                      </Icon>
                      <span>{{ $t("components.cardForm.emailInputDescription") }}</span>
                  </div>
              </div>
              <div class="payment-process__offer" v-if="offerLink || staticOfferLink || staticAgreementLink" v-skeleton="loader.isShowSkeleton()">
                <Text type="caption" class="inline-offer">
                  {{ $t("app.process.offer.text") }}
                  <template v-if="offerLink"><a class="link" target="_blank" :href="offerLink">{{
                      $t("app.process.offer.link") }}</a>
                  </template>
                  <template v-if="staticOfferLink && offerLink">{{ $t("app.process.staticOffer.before") }}</template>
                  <template v-if="staticOfferLink">
                    <a class="link link_secondary" target="_blank"
                      :href="staticOfferLink">{{ $t("app.process.staticOffer.link") }}</a>{{
                    $t("app.process.staticOffer.after")
                    }}
                  </template>
                  <template v-if="staticAgreementLink">
                    {{
                    $t("app.process.staticAgreement.before") }}<a class="link link_secondary" target="_blank"
                      :href="staticAgreementLink">{{ $t("app.process.staticAgreement.link") }}</a>{{
                    $t("app.process.staticAgreement.after") }}
                  </template>
                </Text>
              </div>
              <div class="payment-process__offer" v-if="agreementLink">
                <Text type="caption" class="inline-offer">
                  {{ $t("app.process.confirm.text") }} <a class="link" target="_blank" :href="agreementLink">{{ $t("app.process.confirm.link") }}</a>
                </Text>
              </div>
            </div>
            <div class="payment-process__action" v-if="state.paymentMethod && showMoreMethodsButton" v-skeleton="loader.isShowSkeleton()">
              <Button type="tertiary" @click="onChangePaymentMethod(null)">
                {{ $t("app.process.payment.other") }}
              </Button>
            </div>
          </div>
        </Step>
        <Step name="cash_payment">
          <div class="cash-box">
            <TextField name="name" :label="$t('components.cash.name')"
              v-model="v$.name.$model"
              :validation="cashFormRules.name" />
            <TextField name="email" :label="$t('components.cash.email')" inputmode="email"
              v-model="v$.email.$model"
              :validation="cashFormRules.email"
              type="text" />
          </div>
          <div class="cash-title">{{ $t("components.cash.payWith") }}</div>
          <div class="cash-box">
            <div style="width: 100%" v-for="cashMethods in (cashSettings.CashMethods.includes(1) ? [1,0] : [0,1])">
              <div class="pay-button">
                <template v-if="cashMethods === 1">
                  <Button class="cash-button" style="color: #222D41 !important" type="secondary" @click="payByCash(CashMethods.CStores)"
                          :loading="cash.isLoading" :disabled="cash.isLoading || !cashSettings.CashMethods.includes(1) || !isValidCashForm()">
                    <div>{{ $t("components.cash.convenienceStore") }}</div>
                    <Icon width="22" height="27">
                        <SevenElevenLogo />
                    </Icon>
                    <Icon width="27" height="27">
                        <WallmartLogo />
                    </Icon>
                    <div style="margin-right: -4px;">+ 10</div>
                    <div>{{ $t("components.cash.stores") }}</div>
                  </Button>
                </template>
                <template v-else-if="cashMethods === 0">
                  <Button class="cash-button" style="color: #222D41 !important" type="secondary" @click="payByCash(CashMethods.Oxxo)"
                          :loading="cash.isLoading" :disabled="cash.isLoading || !cashSettings.CashMethods.includes(0) || !isValidCashForm()">
                    <div>OXXO</div>
                    <Icon width="59" height="27">
                        <OxxoLogo />
                    </Icon>
                  </Button>
                </template>
              </div>
              <div class="cash-note cash-note__small" style="margin: 8px 0 0">
                <template v-if="cashMethods === 1 && !cashSettings.CashMethods.includes(1)">{{ $t("components.cash.notAvailableNote") }}</template>
                <template v-else-if="cashMethods === 0 && !cashSettings.CashMethods.includes(0)">{{ $t("components.cash.notAvailableNote") }}</template>
              </div>
            </div>
          </div>
          <div class="cash-note">{{ $t("components.cash.paymentNote") }}</div>
        </Step>
        <Step name="cash_barcode">
          <div class="info-indicator__box">
            <div>
              <Icon width="52" height="52" class="info-indicator__icon-desktop">
                <InfoIndicatorCash/>
              </Icon>
              <Icon width="120" height="120" class="info-indicator__icon-mobile">
                <InfoIndicatorCashBig/>
              </Icon>
            </div>
            <div>
              <div>{{ $t("components.cash.saveBarcode") }}</div>
              <div class="cash-note cash-note__small cash-note__barcode">
                {{ $t("components.cash.saveBarcodeDescription", { 'storeName': $t(`components.cash.${selectedCashMethod === CashMethods.Oxxo ? 'oxxo': 'convenience'}`)}) }}
              </div>
            </div>
          </div>
          <div class="cash-barcode__buttons">
            <a class="cash-barcode__button-open" :href="barcodeCashLink" target="_blank" :disabled="barcodeCashLinkOpened" @click="onOpenBarcodeLink()">
              {{ $t("components.cash.openBarcode") }}
            </a>
            <div class="payment-process__header-action" style="color: #00B3ED; display: block !important" @click="onChangePaymentMethod(null)">
              {{ $t("components.cash.otherMethods") }}
            </div>
          </div>
        </Step>
      </Process>
    </template>
</Card>

<div v-if="isSpei">
    <Spei
            :altPayData="altPayResponseData"
            :email="receiptEmail"
            :terminalName="terminalName"
            @onBack="goToPaymentProcess()"
            @onSuccessSpei="onSuccessSpei()"
    />
</div>
