import {
  ExternalPaymentMethod,
  TerminalInfoResponseModel,
  SaveCard,
} from "@cloudpayments/api-client";

export class TerminalInfo {
  public readonly TerminalUrl: string;
  public readonly IsCharity: boolean;
  public readonly IsTest: boolean;
  public readonly ExternalPaymentMethods: ExternalPaymentMethod[];
  public readonly AgreementPath: string;
  public readonly IsCvvRequired: boolean;
  public readonly SkipExpiryValidation: boolean;
  public readonly IsSaveCard: SaveCard;
  public readonly DisplayAdvertiseBannerOnWidget?: boolean;
  public readonly BannerAdvertiseUrl?: string;

  constructor(terminalInfo: TerminalInfoResponseModel) {
    this.TerminalUrl = terminalInfo.TerminalUrl;
    this.IsCharity = terminalInfo.IsCharity;
    this.IsTest = terminalInfo.IsTest;
    this.ExternalPaymentMethods = [...terminalInfo.ExternalPaymentMethods];
    this.AgreementPath = terminalInfo.AgreementPath;
    this.IsCvvRequired = terminalInfo.IsCvvRequired;
    this.SkipExpiryValidation = terminalInfo.SkipExpiryValidation;
    this.IsSaveCard = terminalInfo.Features.IsSaveCard;
    this.DisplayAdvertiseBannerOnWidget =
      terminalInfo.DisplayAdvertiseBannerOnWidget;
    this.BannerAdvertiseUrl = terminalInfo.BannerAdvertiseUrl;
  }
}
