<Card>
    <template #header>
      <div v-skeleton="loader.isShowSkeleton()">
        <Text type="h3" class="title desktop">
          {{ !isCharity ? $t('app.items.header.default') : $t('app.items.header.charity') }}
        </Text>
        <Text type="h4" class="title mobile">
          {{ !isCharity ? $t('app.items.header.default') : $t('app.items.header.charity') }}
        </Text>
      </div>
    </template>
    <template #content>
        <Table class="desktop table-desktop" v-skeleton="loader.isShowSkeleton()">
            <tr>
                <th width="280px">
                  <Text type="caption">
                    {{ $t('app.items.name') }}
                  </Text>
                </th>
                <th>
                  <Text type="caption">
                    {{ $t('app.items.cost') }}
                  </Text>
                </th>
                <th>
                  <Text type="caption">
                    {{ $t('app.items.count') }}
                  </Text>
                </th>
                <th>
                  <Text type="caption">
                    {{ $t('app.items.sum') }}
                  </Text>
                </th>
            </tr>
            <tr  v-for="(item, index) in items" :key="index">
                <td>
                    {{ item.label }}
                </td>
                <td>
                    {{ getFormattedAmount(item.price) }}
                </td>
                <td>
                    {{ item.quantity }}
                </td>
                <td>
                    {{ getFormattedAmount(item.amount) }}
                </td>
            </tr>
          <tr>
            <td colspan="3">
              <Text type="h4" class="title">
                {{ $t('app.items.total') }}
              </Text>
            </td>
            <td colspan="1">
              <Text type="h4" class="title">
                {{ getFormattedTotalAmount() }}
              </Text>
            </td>
          </tr>
        </Table>
        <Table class="mobile table-mobile" v-skeleton="loader.isShowSkeleton()">
        <tr  v-for="(item, index) in items" :key="index">
          <td colspan="3" class="label border-top">
            {{ item.label }}
          </td>
          <td colspan="2" class="border-top">
            <div class="cost">
              <div class="amount">
                {{ getFormattedAmount(item.amount) }}
              </div>
              <div class="price">
                {{ getFormattedAmount(item.price) }} x {{ item.quantity }}
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="3">
            <Text type="h5" class="title">
              {{ $t('app.items.total') }}
            </Text>
          </td>
          <td colspan="2">
            <div class="cost">
              <div class="amount">
                <Text type="h5" class="title">
                  {{ getFormattedTotalAmount() }}
                </Text>
              </div>
            </div>
          </td>
        </tr>
      </Table>
    </template>
</Card>
