<Header :direction="'vertical'" :brand="brand">
  <template #title>
    <div class="installment__title">{{ title }}</div>
  </template>
  <template #cost>
    <div class="installment__amount">{{ getFormattedAmount(amount) }}</div>
  </template>
</Header>

<Slider
  :model-value="currentIndexSlider"
  :steps="steps"
  :amount="amount"
  currencySymbol="₽"
  @update:modelValue="updateCurrentInstallmentValue($event)"
>
</Slider>

<Button type="primary" class="installment__button-pay" @click="goToInstallment()">
  Далее
</Button>
<Button type="secondary" class="installment__button-back" @click="goBack()">
  Выбрать другой способ
</Button>
