export enum PaymentMethodEnum {
  Card = "card",
  YandexPay = "yandex",
  ApplePay = "apple",
  GooglePay = "google",
  Tinkoff = "tinkoff",
  Loan = "loan",
  SBP = "sbp",
  TinkoffPay = "tinkoffPay",
  Spei = "spei",
  Som = "som",
  Dolyame = "dolyame",
  SberPay = "sberPay",
  Cash = "cash",
}

export interface PaymentProcessState {
  success: boolean;
  paymentMethod: PaymentMethodEnum | null;
  reasonCode: number | null;
}
