import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-891e7c34"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "qr" }
const _hoisted_2 = { class: "logo" }
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 1,
  class: "other"
}
const _hoisted_5 = {
  key: 4,
  class: "attention"
}
const _hoisted_6 = { class: "icon" }
const _hoisted_7 = { class: "description" }

export function render(_ctx, _cache) {
  const _component_Sbp3Icon = _resolveComponent("Sbp3Icon")
  const _component_Icon = _resolveComponent("Icon")
  const _component_TinkoffPayIconBig = _resolveComponent("TinkoffPayIconBig")
  const _component_SberPayLogo = _resolveComponent("SberPayLogo")
  const _component_Text = _resolveComponent("Text")
  const _component_LoaderSpinner = _resolveComponent("LoaderSpinner")
  const _component_Button = _resolveComponent("Button")
  const _component_Alert = _resolveComponent("Alert")
  const _component_AttentionIcon = _resolveComponent("AttentionIcon")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.mode === _ctx.QrMode.SBP)
        ? (_openBlock(), _createBlock(_component_Icon, {
            key: 0,
            width: "89",
            height: "50"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_Sbp3Icon)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.mode === _ctx.QrMode.TinkoffPay)
        ? (_openBlock(), _createBlock(_component_Icon, {
            key: 1,
            width: "68",
            height: "28"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_TinkoffPayIconBig, {
                width: 68,
                height: 28
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.mode === _ctx.QrMode.SberPay)
        ? (_openBlock(), _createBlock(_component_Icon, {
            key: 2,
            width: "76",
            height: "36"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_SberPayLogo, {
                width: 76,
                height: 36
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    (_ctx.qrState.showCode || _ctx.qrState.isLoading)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["title", { loading: _ctx.qrState.isLoading }])
        }, [
          (_ctx.mode === _ctx.QrMode.TinkoffPay)
            ? (_openBlock(), _createBlock(_component_Text, {
                key: 0,
                type: "p3"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('components.tpay.scanFromPhone')), 1)
                ]),
                _: 1
              }))
            : (_openBlock(), _createBlock(_component_Text, {
                key: 1,
                type: "p3"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('components.sbp.scanFromPhone')), 1)
                ]),
                _: 1
              }))
        ], 2))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["code", {'code-tinkoffpay': _ctx.mode === _ctx.QrMode.TinkoffPay, 'code-sberpay': _ctx.mode === _ctx.QrMode.SberPay}])
    }, [
      (_ctx.qrState.showCode && _ctx.qrImage)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            class: _normalizeClass({'code-sberpay': _ctx.mode === _ctx.QrMode.SberPay}),
            src: _ctx.getQrImage(),
            alt: ""
          }, null, 10, _hoisted_3))
        : _createCommentVNode("", true),
      (_ctx.qrState.isLoading)
        ? (_openBlock(), _createBlock(_component_LoaderSpinner, {
            key: 1,
            size: "l"
          }))
        : _createCommentVNode("", true)
    ], 2),
    (!_ctx.qrState.status !== 'awaitingAuthentication')
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_Button, {
            type: "secondary",
            onClick: _ctx.toMain
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('components.sbp.anotherMethod')), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ]))
      : _createCommentVNode("", true),
    (!_ctx.qrState.showCode && !_ctx.qrState.isLoading)
      ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: "repeat",
          onClick: _cache[0] || (_cache[0] = (...args) => (_ctx.generateQRImage && _ctx.generateQRImage(...args)))
        }, [
          _createVNode(_component_Button, { type: "primary" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('components.sbp.retry')), 1)
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    (!_ctx.qrState.status === 'awaitingAuthentication')
      ? (_openBlock(), _createBlock(_component_Alert, {
          key: 3,
          type: "process"
        }, {
          content: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('components.sbp.awaitingAuthentication')), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (!_ctx.qrState.showCode && !_ctx.qrState.isLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_Icon, {
              width: "16",
              height: "16"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_AttentionIcon)
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_Text, { type: "caption" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('components.sbp.timeError')), 1)
              ]),
              _: 1
            })
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}