/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum KkmServiceDto_Common_VersionFfd {
    '_0' = 0,
    '_10' = 10,
    '_11' = 11,
    '_12' = 12,
    '_105' = 105,
}
