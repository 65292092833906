import { PaymentService } from "./PaymentService";
import { ApiClient } from "./ApiClient";
import {
  CalculateInstallmentsMX,
  HttpClient,
  TransactionInfoResponse,
  ExternalPaymentMethods,
  TerminalInfoResponseModel,
  ExternalPaymentMethod,
} from "@cloudpayments/api-client";

import { IOrderOptions, OrderOptions } from "@src/contracts/OrderOptions";
import { TinkoffInstallmentApi } from "@src/services/externalApiServices/TinkoffPay/TinkoffInstallmentApi";
import { YandexPayApi } from "@src/services/externalApiServices/YandexPay/YandexPayApi";
import { DomainConfiguration } from "@src/runtime-configuration/DomainConfiguration";
import { AppClient } from "@src/services/api";
import { ref } from "vue";
import { Logger } from "@cloudpayments/vue-utils";

export class AppService {
  private readonly apiClient: ApiClient;
  public payment!: PaymentService;
  private _orderOptions!: OrderOptions;
  // TODO: Dirty quick fix, remove on refactoring
  public readonly email = ref<string>();
  public orderFormOptions = ref<{ saveCard?: boolean }>({});
  private _speiAlertType = ref();

  constructor(public readonly runtimeConfiguration: DomainConfiguration) {
    this.apiClient = new ApiClient(
      new HttpClient(this.runtimeConfiguration.api.host),
      runtimeConfiguration,
      new AppClient({ BASE: this.runtimeConfiguration.api.host })
    );
    YandexPayApi.setDomainConfiguration(this.runtimeConfiguration);
  }

  public async getUpdatedInstallmentMxData(
    terminalPublicId: string,
    amount: number
  ) {
    return this.apiClient.installmentMxData(terminalPublicId, amount);
  }

  public getSpeiAlertType() {
    return this._speiAlertType;
  }

  public setSpeiAlertType(alertDataType: string | null) {
    this._speiAlertType.value = alertDataType;
  }

  public async getOrder(publicOrderId: string): Promise<OrderOptions> {
    const orderOptions = await this.apiClient.order(publicOrderId);
    let installmentData;

    try {
      if (
        orderOptions.merchantConfiguration.ExternalPaymentMethods.find(
          (method) => method.Type === ExternalPaymentMethods.CardInstallmentMX
        )?.Enabled &&
        !orderOptions?.paid
      ) {
        installmentData = await this.getUpdatedInstallmentMxData(
          orderOptions.terminalPublicId,
          orderOptions.amount
        );
      }
    } catch {
      Logger.LogError("Get installments data is not success");
    }

    this.combinePayment(
      publicOrderId,
      orderOptions,
      orderOptions.merchantConfiguration || {},
      true,
      installmentData?.Success ? installmentData : undefined
    );
    return this._orderOptions;
  }

  public getTransactionInfo(
    transactionId: string
  ): Promise<TransactionInfoResponse> {
    return this.apiClient.transactionInfo(transactionId);
  }

  public async getSubscription(
    publicSubscriptionId: string
  ): Promise<OrderOptions> {
    const orderOptions = await this.apiClient.subscription(
      publicSubscriptionId
    );
    const terminalInfo = await this.apiClient.terminalInfo(
      orderOptions.terminalPublicId,
      window.location.origin,
      orderOptions.cultureName
    );
    this.combinePayment(
      publicSubscriptionId,
      orderOptions,
      terminalInfo.Model,
      false
    );
    return this._orderOptions;
  }

  public combinePayment(
    publicOrderId: string,
    orderOptions: IOrderOptions,
    terminalInfo: TerminalInfoResponseModel,
    isOrder: boolean,
    installmentDataMX?: CalculateInstallmentsMX
  ): void {
    this._orderOptions = new OrderOptions(
      orderOptions,
      terminalInfo,
      isOrder,
      publicOrderId,
      installmentDataMX
    );
    this.payment = new PaymentService(
      this.apiClient,
      orderOptions.terminalPublicId,
      this.runtimeConfiguration
    );
  }

  public getOrderOptions(): OrderOptions {
    return this._orderOptions;
  }

  public getPayment(): PaymentService {
    return this.payment as PaymentService;
  }

  public async isApplePayAvailable(): Promise<boolean> {
    return (
      (await this.getOrderOptions().isApplePayAvailable()) &&
      "ApplePaySession" in window &&
      ApplePaySession.canMakePayments()
    );
  }

  public async isGooglePayAvailable(): Promise<boolean> {
    return (
      (await this.getOrderOptions().isGooglePayAvailable()) &&
      (await this.payment.googlePayApi.isGooglePayAvailable())
    );
  }

  public async isTinkoffInstallmentAvailable(): Promise<boolean> {
    return (
      (await this.getOrderOptions().isTinkoffInstallmentAvailable()) &&
      (await TinkoffInstallmentApi.isPayAvailable())
    );
  }

  public async isTinkoffCreditAvailable(amount: number): Promise<boolean> {
    return (
      amount >= 3500 &&
      amount <= 500000 &&
      (await this.getOrderOptions().isTinkoffCreditAvailable()) &&
      (await TinkoffInstallmentApi.isPayAvailable())
    );
  }

  public async isYandexPayAvailable(): Promise<boolean> {
    return (
      (await this.getOrderOptions().isYandexPayAvailable()) &&
      (await YandexPayApi.isYandexPayAvailable())
    );
  }

  public async isSBPAvailable(): Promise<boolean> {
    return (
      this.getOrderOptions().isSbpAvailable() &&
      this.getOrderOptions().isSingleMessageScheme()
    );
  }

  public async isTinkoffPayAvailable(): Promise<boolean> {
    return this.getOrderOptions().isTinkoffPayAvailable();
  }

  public async isSberPayAvailable(): Promise<boolean> {
    return this.getOrderOptions().isSberPayAvailable();
  }

  public async isSpeiAvailable(): Promise<boolean> {
    return this.getOrderOptions().isSpeiAvailable();
  }

  public async isCashAvailable(): Promise<boolean> {
    return this.getOrderOptions().isCashAvailable();
  }

  public getCashSettings(): ExternalPaymentMethod | undefined {
    return this.getOrderOptions().getCashSettings();
  }

  public async isDolyameAvailable(): Promise<boolean> {
    return this.getOrderOptions().isDolyameAvailable();
  }

  public async isSomAvailable(): Promise<boolean> {
    return this.getOrderOptions().isSomAvailable();
  }

  public async isInstallmentCardMxAvailable(): Promise<boolean> {
    return (
      this.getOrderOptions().isInstallmentMxAvailable() &&
      !!this._orderOptions.installmentDataMX?.Model?.IsCardInstallmentAvailable
    );
  }
}
