<Card direction="vertical">
    <template #content>
        <Header
          v-skeleton="loader.isShowSkeleton()"
                :direction="'horizontal'"
                :brand="order?.terminalLogoUrl">
          <template #title>
            <div class="desktop header-title" v-skeleton="loader.isShowSkeleton()">
              <Text type="h3">
                {{ title }}
              </Text>
            </div>
            <div class="mobile header-title" v-skeleton="loader.isShowSkeleton()">
              <Text type="h4">
                {{ title }}
              </Text>
            </div>
          </template>
          <template #cost>
            <div class="desktop header-cost" v-skeleton="loader.isShowSkeleton()">
              <Text type="p0">
                {{ `${formattedAmount}` }}
              </Text>
            </div>
            <div class="mobile header-cost" v-skeleton="loader.isShowSkeleton()">
              <Text type="p1">
                {{ `${formattedAmount}` }}
              </Text>
            </div>
          </template>
          <template #description v-if="installmentData">
              <div class="installment-data">{{ installmentData }}</div>
          </template>
        </Header>
        <div class="result" v-if="successPaymentMethod">
          <Alert type="success">
              <template #content>{{ $t('app.header.success', { 'method': $t(`app.header.methods.${successPaymentMethod}`)}) }}</template>
          </Alert>
        </div>

        <div class="header-wrapper">
            <div v-if="speiAlertDataType === 'process'">
                <Alert :type="'process'" class="spei__alert">
                    <template v-slot:content>
                        <span class="spei__alert-text">{{ $t("components.spei.alertTextWarning") }}</span>
                    </template>
                </Alert>
            </div>

            <div v-if="speiAlertDataType === 'success'">
                <Alert :type="'success'" class="spei__alert">
                    <template v-slot:content>
                        <span class="spei__alert-text">{{ $t("components.spei.alertTextSuccess") }}</span>
                    </template>
                </Alert>
            </div>

            <div v-if="speiAlertDataType === 'error'">
                <Alert :type="'error'" class="spei__alert">
                    <template v-slot:content>
                        <div class="spei__alert-error-title">{{ $t("components.spei.alertTextErrorTitle") }}</div>
                        <div class="spei__alert-error-subtitle">{{ $t("components.spei.alertTextErrorSubtitle") }}</div>
                    </template>
                </Alert>
            </div>
        </div>
        <!-- TODO: Remove duplicates, in Text - the type and use of div are confusing. In general, this should probably be solved using CSS styles, like Taiga https://taiga-ui.dev/typography, and not a separate component -->
        <Text type="inline" class="order" v-if="isOrder" v-skeleton="loader.isShowSkeleton()">
            <Text type="h4" class="title desktop">{{ !isCharity ? $t('app.header.number') : $t('app.header.charity.number') }}:</Text>
            <Text type="h5" class="title mobile">{{ !isCharity ? $t('app.header.number') : $t('app.header.charity.number') }}:</Text>
            <Text type="p1" class="desktop">№{{ order?.orderNumber }}</Text>
            <Text type="p2" class="mobile">№{{ order?.orderNumber }}</Text>
        </Text>
        <Text type="inline" class="shop" v-skeleton="loader.isShowSkeleton()">
            <Text type="h4" class="title desktop">{{ !isCharity ? $t('app.header.shop') : $t('app.header.charity.shop') }}:</Text>
            <Text type="h5" class="title mobile">{{ !isCharity ? $t('app.header.shop') : $t('app.header.charity.shop') }}:</Text>
            <Text type="p1" class="desktop">{{ order?.terminalName }}</Text>
            <Text type="p2" class="mobile">{{ order?.terminalName }}</Text>
        </Text>
        <Text type="inline" v-if="order == null || (order?.paid && order?.email)" class="email" v-skeleton="loader.isShowSkeleton()">
          <Text type="h4" class="title desktop">E-mail:</Text>
          <Text type="h5" class="title mobile">E-mail:</Text>
          <Text type="p1" class="desktop">{{ order?.email }}</Text>
          <Text type="p2" class="mobile">{{ order?.email }}</Text>
        </Text>
        <Text type="inline" class="subscription" v-if="subscription?.recurrentInterval !== null" v-skeleton="loader.isShowSkeleton()">
          <Text type="inline" class="desktop">
            <Text type="h4" class="title">{{ $t('app.header.subscription.title') }}:</Text>
            <Text type="p1">{{ $t('app.header.subscription.description') }} <span v-if="subscription && subscription?.recurrentInterval !== null">{{ periodIntervalText }}</span></Text>
          </Text>
          <Text type="inline" class="mobile">
            <Text type="h5" class="title">{{ $t('app.header.subscription.title') }}:</Text>
            <Text type="p2">{{ $t('app.header.subscription.description') }} <span v-if="subscription && subscription?.recurrentInterval !== null">{{ periodIntervalText }}</span></Text>
          </Text>
        </Text>
        <div v-skeleton="loader.isShowSkeleton()">
          <Text type="p2" class="description" v-skeleton="loader.isShowSkeleton()">{{ order?.description }}</Text>
        </div>
        <div v-if="successPaymentMethod && order?.terminalInfo?.DisplayAdvertiseBannerOnWidget" class="banner-wrapper">
            <AdvertiseBanner :banner-url="bannerUrl" :payment-method-statist="paymentMethodStatist"></AdvertiseBanner>
        </div>
        <div class="action" v-if="successPaymentMethod && order?.successRedirectUrl">
          <Button type="primary" @click="backToSite(order?.successRedirectUrl)">
            {{ $t(!isCharity ? 'app.process.result.back.default' : 'app.process.result.back.charity') }}
          </Button>
        </div>
      <div class="action" v-if="failPaymentMethod && order?.failRedirectUrl">
        <Button type="primary" @click="backToSite(order?.failRedirectUrl)">
          {{ $t(!isCharity ? 'app.process.result.back.default' : 'app.process.result.back.charity') }}
        </Button>
      </div>
      <slot name="transactionInfo"></slot>
    </template>
</Card>
