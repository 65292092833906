<Card>
    <template #header>
        <div class="spei__header">
            <div class="spei__title">{{ $t("components.spei.title") }}</div>
            <div class="spei__subtitle">{{ $t("components.spei.subtitle") }}</div>
        </div>
    </template>

    <template #content>
        <div class="spei__content">
            <div class="spei__form-wrapper">
                <TextField class="spei__text-field" :readonly="true" :label="$t('components.spei.clabe')" v-model="form.clabe">
                    <template v-slot:icons>
                        <Icon
                                width="16"
                                height="16"
                                class="spei__copy-icon"
                                @click="copyValueByFieldKey('clabe')"
                        >
                            <CopySmallIcon/>
                        </Icon>
                    </template>
                </TextField>
                <TextField class="spei__text-field" :readonly="true" :label="$t('components.spei.monto')" v-model="form.amount">
                    <template v-slot:icons>
                        <Icon
                                width="16"
                                height="16"
                                class="spei__copy-icon"
                                @click="copyValueByFieldKey('amount')"
                        >
                            <CopySmallIcon/>
                        </Icon>
                    </template>
                </TextField>
                <TextField class="spei__text-field" :readonly="true" :label="$t('components.spei.banco')" v-model="form.bank">
                    <template v-slot:icons>
                        <Icon
                                width="16"
                                height="16"
                                class="spei__copy-icon"
                                @click="copyValueByFieldKey('bank')"
                        >
                            <CopySmallIcon/>
                        </Icon>
                    </template>
                </TextField>
                <TextField class="spei__text-field" :readonly="true" :label="$t('components.spei.paymentConcept')" v-model="form.paymentConcept">
                    <template v-slot:icons>
                        <Icon
                                width="16"
                                height="16"
                                class="spei__copy-icon"
                                @click="copyValueByFieldKey('paymentConcept')"
                        >
                            <CopySmallIcon/>
                        </Icon>
                    </template>
                </TextField>
            </div>
            <div class="spei__date">
                <span class="spei__date-left">{{$t('components.spei.payBefore')}}</span>
                <span class="spei__date-right">{{localizedExpiredDate}}{{ $t("components.spei.dateAt") }}{{localizedExpiredTime}}</span>
            </div>
            <div class="spei__line"></div>
            <div class="spei__email-wrapper">
                <template v-if="isEmailInput">
                    <div class="spei__email-description">{{$t('components.spei.sendEmail')}}</div>
                    <div class="spei__email-error" v-if="isEmailError">
                        <Alert :type="'error'">
                            <template v-slot:content>
                                <div class="spei__email-error-title">{{ $t("components.spei.emailErrorTitle") }}</div>
                                <div class="spei__email-error-subtitle">{{ $t("components.spei.emailErrorSubtitle") }}</div>
                            </template>
                        </Alert>
                    </div>
                    <div class="spei__email-row">
                        <TextField class="spei__email-input" :label="$t('components.spei.email')" v-model="v$.email.$model" :validation="formRules.email"></TextField>
                        <button class="spei__telegram-wrapper" @click="sentPaymentDetails()">
                            <Icon width="24" height="24">
                                <TelegramIcon/>
                            </Icon>
                        </button>
                    </div>
                </template>
                <template v-if="!isEmailInput">
                    <div class="spei__send-email">
                        <div class="spei__send-email-text">
                            <span>{{ $t("components.spei.sendEmailByAddress") }}</span>
                            <span class="spei__email-value">{{ form.email }}</span>
                        </div>
                        <div @click="isEmailInput = true" class="spei__send-email-button_desktop">
                            <Button type="secondary" class="spei__send-email-button">{{ $t("components.spei.sendToAnotherEmailDesktop") }}</Button>
                        </div>
                        <div @click="isEmailInput = true" class="spei__send-email-button_mobile">
                            <Button type="secondary" class="spei__send-email-button">{{ $t("components.spei.sendToAnotherEmail") }}</Button>
                        </div>
                    </div>
                </template>
            </div>
            <div class="spei__line"></div>
            <div class="spei__footer">
                <div class="spei__footer-text">{{$t('components.spei.footerDescription')}}</div>
                <div class="spei__other-method">
                    <div class="spei__other-method-button">
                        <Icon width="20" height="20">
                            <LeftArrowIcon/>
                        </Icon>
                    </div>
                    <div class="spei__other-method-text" @click="onBack()">{{$t('components.spei.otherMethod')}}</div>
                </div>
            </div>
        </div>
    </template>
</Card>
