<template src="./App.html"></template>

<script lang="ts">
import {
  defineComponent,
  inject,
  onMounted,
  provide,
  reactive,
  ref,
} from "vue";

import { init, setTransport } from "@amplitude/analytics-browser";

import {
  BottomBarService,
  LoaderService,
  Modal,
  ModalService,
  NotificationService,
  Notification,
} from "@cloudpayments/vue-ui-kit";

import { AppService } from "@src/services/AppService";

import Home from "@src/views/Home/Home.vue";

import LoaderWrap from "@src/components/LoaderWrap/LoaderWrap.vue";
import FingerprintJS, { Agent, GetResult } from "@fingerprintjs/fingerprintjs";
import { TransportType } from "@amplitude/analytics-types";
import { DomainConfigurationKey } from "@src/injections";
import { SbpApi } from "@src/services/externalApiServices/SBP/sbpApi";
import { TinkoffPayApi } from "@src/services/externalApiServices/TinkoffPay/tinkoffPayApi";
import { AltPayApi } from "@src/services/externalApiServices/TinkoffPay/altPayApi";
import { SessionStorageService } from "@src/services/SessionStorageService";
import { SberPayApi } from "@src/services/externalApiServices/SberPay/sberPayApi";

export default defineComponent({
  name: "App",
  components: {
    Home,
    Modal,
    LoaderWrap,
    Notification,
  },
  setup() {
    let showContent = ref(false);
    const runtimeConfiguration = inject(DomainConfigurationKey)!;

    const loader = reactive(new LoaderService());
    const modal = reactive(new ModalService());
    const appService = new AppService(runtimeConfiguration);
    const bottomBar = reactive(new BottomBarService());
    const sbpApi = reactive(new SbpApi(runtimeConfiguration));
    const tinkoffPayApi = reactive(new TinkoffPayApi(runtimeConfiguration));
    const sberPayApi = reactive(new SberPayApi(runtimeConfiguration));
    const altPayApi = reactive(new AltPayApi(runtimeConfiguration));
    const sessionStorageService = reactive(new SessionStorageService());
    const notification = reactive(new NotificationService());

    const initAmplitude = () => {
      if (runtimeConfiguration.amplitudeSettings?.apiKey) {
        return FingerprintJS.load()
          .then((result: Agent) => result.get())
          .then((result: GetResult) => {
            init(
              runtimeConfiguration.amplitudeSettings!.apiKey!,
              result.visitorId
            );
            setTransport(TransportType.SendBeacon);
          });
      }
      return Promise.resolve();
    };

    onMounted(async () => {
      loader.showLoader(3);
      initAmplitude().finally(() => {
        showContent.value = true;
      });
    });

    provide("LoaderService", loader);
    provide("ModalService", modal);
    provide("AppService", appService);
    provide("BottomBarService", bottomBar);

    //TODO refactoring избавиться от правайда qr сервиса см. https://cloudpayments.atlassian.net/browse/ITD-5674
    provide("SbpApi", sbpApi);
    provide("TinkoffPayApi", tinkoffPayApi);
    provide("SberPayApi", sberPayApi);
    provide("AltPayApi", altPayApi);
    provide("SessionStorageService", sessionStorageService);
    provide("NotificationService", notification);

    return {
      showContent,
      loader,
    };
  },
});
</script>

<style src="./App.scss" scoped lang="scss"></style>
