export class SessionStorageService {
  get<T>(key: string): T | null {
    const json = sessionStorage.getItem(key);
    if (json) {
      try {
        return <T>JSON.parse(json);
      } catch (error) {
        console.log(error);
      }
    }

    return null;
  }

  set<T>(key: string, data: T) {
    // если будет попытка сохранить свыше max size sessionStorage
    try {
      const json = JSON.stringify(data);
      sessionStorage.setItem(key, json);
    } catch (error) {
      console.log(error);
    }
  }

  delete(key: string) {
    sessionStorage.removeItem(key);
  }

  deleteAll() {
    sessionStorage.clear();
  }
}
