import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5dae736a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app" }
const _hoisted_2 = { class: "checkout" }
const _hoisted_3 = {
  key: 0,
  class: "cloud"
}

export function render(_ctx, _cache) {
  const _component_Modal = _resolveComponent("Modal")
  const _component_Notification = _resolveComponent("Notification")
  const _component_LoaderWrap = _resolveComponent("LoaderWrap")
  const _component_router_view = _resolveComponent("router-view")
  const _component_SecuredIcon = _resolveComponent("SecuredIcon")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Modal),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Notification, { type: "error" }),
      _createElementVNode("div", _hoisted_2, [
        (_ctx.loader?.isShowLoader())
          ? (_openBlock(), _createBlock(_component_LoaderWrap, {
              key: 0,
              transparent: _ctx.loader?.isShowTransparent(),
              showText: _ctx.loader?.withText()
            }, null, 8, ["transparent", "showText"]))
          : _createCommentVNode("", true),
        _createVNode(_component_router_view)
      ]),
      (_ctx.showContent)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_SecuredIcon)
          ]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}