import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createVNode as _createVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-394b2e5a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "home" }
const _hoisted_2 = {
  key: 0,
  style: {"width":"100%"}
}
const _hoisted_3 = { class: "details__wrap2" }
const _hoisted_4 = { style: {"flex":"1"} }
const _hoisted_5 = { class: "details__label" }
const _hoisted_6 = {
  class: "details__value",
  style: {"display":"flex"}
}
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { class: "details__label" }
const _hoisted_9 = { class: "details__value" }
const _hoisted_10 = { class: "details__label" }
const _hoisted_11 = { class: "details__value" }
const _hoisted_12 = { style: {"flex":"1"} }
const _hoisted_13 = { class: "details__label" }
const _hoisted_14 = { class: "details__value" }
const _hoisted_15 = { class: "details__label" }
const _hoisted_16 = { class: "details__value" }
const _hoisted_17 = { class: "details__label" }
const _hoisted_18 = { class: "details__value" }

export function render(_ctx, _cache) {
  const _component_CardIcon = _resolveComponent("CardIcon")
  const _component_DownBlueIcon = _resolveComponent("DownBlueIcon")
  const _component_Button = _resolveComponent("Button")
  const _component_OrderHeader = _resolveComponent("OrderHeader")
  const _component_PaymentProcess = _resolveComponent("PaymentProcess")
  const _component_OrderContents = _resolveComponent("OrderContents")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.order)
      ? (_openBlock(), _createBlock(_component_OrderHeader, {
          key: 0,
          isCharity: _ctx.isCharity,
          showOrderId: true,
          title: !_ctx.isCharity ? _ctx.$t('app.header.title.order') : _ctx.$t('app.header.title.charity'),
          failPaymentMethod: _ctx.failPaymentMethod,
          successPaymentMethod: _ctx.successPaymentMethod,
          order: _ctx.order,
          "transaction-info": _ctx.transactionInfo
        }, {
          transactionInfo: _withCtx(() => [
            (_ctx.showTransactionDetails && _ctx.transactionInfo && _ctx.transactionInfo.IsCard)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createElementVNode("div", {
                    class: "details__wrap1",
                    style: _normalizeStyle(_ctx.isOpenDetails ? {'grid-template-rows': '1fr'} : {})
                  }, [
                    _createElementVNode("div", _hoisted_3, [
                      _createElementVNode("div", _hoisted_4, [
                        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t("transactionDetails.paymentMethod")), 1),
                        _createElementVNode("div", _hoisted_6, [
                          (_ctx.transactionInfo.CardType)
                            ? (_openBlock(), _createBlock(_component_CardIcon, {
                                key: 0,
                                "card-name": _ctx.transactionInfo.CardType
                              }, null, 8, ["card-name"]))
                            : _createCommentVNode("", true),
                          (_ctx.transactionInfo.LastFour)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_7, " •••• " + _toDisplayString(_ctx.transactionInfo.LastFour), 1))
                            : _createCommentVNode("", true)
                        ]),
                        _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t("transactionDetails.authCode")), 1),
                        _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.transactionInfo.AuthCode || '—'), 1),
                        _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t("transactionDetails.transactionId")), 1),
                        _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.transactionInfo.TransactionId || '—'), 1)
                      ]),
                      _createElementVNode("div", _hoisted_12, [
                        _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.$t("transactionDetails.authDate")), 1),
                        _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.localizedAuthDate || '—'), 1),
                        _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.$t("transactionDetails.affiliationId")), 1),
                        _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.transactionInfo.AffiliationId || '—'), 1),
                        _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.$t("transactionDetails.reference")), 1),
                        _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.transactionInfo.Reference || '—'), 1)
                      ])
                    ])
                  ], 4),
                  _createVNode(_component_Button, {
                    type: "secondary",
                    class: "button_show-details",
                    onClick: _cache[0] || (_cache[0] = $event => (_ctx.isOpenDetails = !_ctx.isOpenDetails))
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t("transactionDetails.info")), 1),
                      _createVNode(_component_DownBlueIcon, {
                        style: _normalizeStyle([{"margin-left":"10px","transition":"transform .2s ease-in"}, _ctx.isOpenDetails ? {transform: 'rotate(180deg)'} : {}])
                      }, null, 8, ["style"])
                    ]),
                    _: 1
                  })
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["isCharity", "title", "failPaymentMethod", "successPaymentMethod", "order", "transaction-info"]))
      : _createCommentVNode("", true),
    (_ctx.showProcess)
      ? (_openBlock(), _createBlock(_component_PaymentProcess, {
          key: 1,
          onOnSuccess: _ctx.onSuccess,
          onOnFail: _ctx.onFail,
          onOnChange: _ctx.onChange,
          order: _ctx.order
        }, null, 8, ["onOnSuccess", "onOnFail", "onOnChange", "order"]))
      : _createCommentVNode("", true),
    (_ctx.showContent)
      ? (_openBlock(), _createBlock(_component_OrderContents, {
          key: 2,
          order: _ctx.order,
          isCharity: _ctx.isCharity
        }, null, 8, ["order", "isCharity"]))
      : _createCommentVNode("", true)
  ]))
}