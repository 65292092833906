<div class="qr">
  <div class="logo">
    <Icon v-if="mode === QrMode.SBP" width="89" height="50">
      <Sbp3Icon />
    </Icon>
    <Icon v-if="mode === QrMode.TinkoffPay" width="68" height="28">
      <TinkoffPayIconBig :width="68" :height="28" />
    </Icon>
    <Icon v-if="mode === QrMode.SberPay" width="76" height="36">
      <SberPayLogo :width="76" :height="36" />
    </Icon>
  </div>

  <div
    class="title"
    v-if="qrState.showCode || qrState.isLoading"
    :class="{ loading: qrState.isLoading }"
  >
    <Text type="p3" v-if="mode === QrMode.TinkoffPay">{{ $t('components.tpay.scanFromPhone') }}</Text>
    <Text type="p3" v-else>{{ $t('components.sbp.scanFromPhone') }}</Text>
  </div>
  <div class="code" :class="{'code-tinkoffpay': mode === QrMode.TinkoffPay, 'code-sberpay': mode === QrMode.SberPay}">
    <img :class="{'code-sberpay': mode === QrMode.SberPay}" v-if="qrState.showCode && qrImage" :src="getQrImage()" alt="" />
    <LoaderSpinner v-if="qrState.isLoading" size="l"></LoaderSpinner>
  </div>
  <div class="other" v-if="!qrState.status !== 'awaitingAuthentication'">
    <Button type="secondary" @click="toMain">
      {{ $t('components.sbp.anotherMethod') }}
    </Button>
  </div>
  <div
    class="repeat"
    v-if="!qrState.showCode && !qrState.isLoading"
    @click="generateQRImage"
  >
    <Button type="primary">{{ $t('components.sbp.retry') }}</Button>
  </div>
  <Alert type="process" v-if="!qrState.status === 'awaitingAuthentication'">
    <template #content
      >{{ $t('components.sbp.awaitingAuthentication') }}</template
    >
  </Alert>
  <div class="attention" v-if="!qrState.showCode && !qrState.isLoading">
    <div class="icon">
      <Icon width="16" height="16">
        <AttentionIcon />
      </Icon>
    </div>
    <div class="description">
      <Text type="caption">{{ $t('components.sbp.timeError') }}</Text>
    </div>
  </div>
</div>
