import { WordsHelper } from "@src/common/words";
import { RecurrentInterval } from "@src/contracts/OrderOptions";

export class SubscriptionHelper {
  public static getInterval(
    interval: RecurrentInterval,
    period: number,
    vueLang: any
  ): string {
    if (interval === RecurrentInterval.Day && period === 1) {
      return vueLang.$t("subscription.interval.day");
    }

    if (interval === RecurrentInterval.Day) {
      const periodString = WordsHelper.declOfNum(period, [
        vueLang.$t("subscription.period.day.day1"),
        vueLang.$t("subscription.period.day.day2"),
        vueLang.$t("subscription.period.day.day3"),
      ]);
      return `${vueLang.$t(
        "subscription.interval.per"
      )} ${period} ${periodString}`;
    }

    if (interval === RecurrentInterval.Week && period === 1) {
      return vueLang.$t("subscription.interval.week");
    }

    if (interval === RecurrentInterval.Week) {
      const periodString = WordsHelper.declOfNum(period, [
        vueLang.$t("subscription.period.week.week1"),
        vueLang.$t("subscription.period.week.week2"),
        vueLang.$t("subscription.period.week.week3"),
      ]);
      return `${vueLang.$t(
        "subscription.interval.per"
      )} ${period} ${periodString}`;
    }

    if (interval === RecurrentInterval.Month && period === 1) {
      return vueLang.$t("subscription.interval.month");
    }

    if (interval === RecurrentInterval.Month) {
      const periodString = WordsHelper.declOfNum(period, [
        vueLang.$t("subscription.period.month.month1"),
        vueLang.$t("subscription.period.month.month2"),
        vueLang.$t("subscription.period.month.month3"),
      ]);
      return `${vueLang.$t(
        "subscription.interval.per"
      )} ${period} ${periodString}`;
    }

    return "";
  }
}
