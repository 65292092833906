<template src="./OrderContents.html"></template>
<script lang="ts">
import { computed, defineComponent, inject, PropType } from "vue";
import { Card, LoaderService, Table, Text } from "@cloudpayments/vue-ui-kit";
import { Utils } from "@cloudpayments/vue-utils";
import { OrderOptions } from "@src/contracts/OrderOptions";
import { DomainConfigurationKey } from "@src/injections";

export default defineComponent({
  name: "OrderContents",
  components: {
    Card,
    Text,
    Table,
  },
  props: {
    isCharity: Boolean,
    order: Object as PropType<OrderOptions>,
  },
  setup(props) {
    const loader = inject<LoaderService>("LoaderService");
    const runtimeConfiguration = inject(DomainConfigurationKey);
    const items = computed(() => {
      return props.order?.items?.items || [];
    });

    function getFormattedTotalAmount(): string {
      return Utils.getFormattedAmountWithCurrency({
        amount: items.value.reduce(
          (part, element) => part + Number(element.amount),
          0
        ),
        currencyCode: props.order?.currency,
        firstDelimiter: runtimeConfiguration?.amountSettings?.firstDelimiter,
        secondDelimiter: runtimeConfiguration?.amountSettings?.secondDelimiter,
        locale: runtimeConfiguration?.amountSettings?.locale,
      });
    }

    const getFormattedAmount = (amount: number) => {
      return Utils.getFormattedAmountWithCurrency({
        amount,
        currencyCode: props.order?.currency,
        firstDelimiter: runtimeConfiguration?.amountSettings?.firstDelimiter,
        secondDelimiter: runtimeConfiguration?.amountSettings?.secondDelimiter,
        locale: runtimeConfiguration?.amountSettings?.locale,
      });
    };

    return {
      items,
      getFormattedTotalAmount,
      getFormattedAmount,
      loader,
    };
  },
});
</script>
<style src="./OrderContents.scss" scoped lang="scss"></style>
