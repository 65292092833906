import { Logger, Utils } from "@cloudpayments/vue-utils";
import { environment } from "@src/environment";
import { DomainConfiguration } from "@src/runtime-configuration/DomainConfiguration";
import mitt, { Emitter } from "mitt";

declare let YaPay: any;

export interface YandexPaymentMethods {
  type: string;
  gateway: string;
  gatewayMerchantId: string;
  allowedAuthMethods: string[];
  allowedCardNetworks: string[];
}

export interface IYandexPay {
  amount: number;
  invoiceId: string;
  publicId: string;
  currency: string;
  requireEmail: boolean;
}

export interface YandexMerchantInfo {
  id: string;
  name: string;
  url?: string;
}

export interface YandexOrder {
  id: string;
  total: { amount: string };
  items: YandexOrderItem[];
}

export interface YandexOrderItem {
  label: string;
  amount: string;
}

export interface RecurringOptions {
  type: string;
  optional: boolean;
}

export interface YandexPaymentData {
  env: string; // "PRODUCTION" | "SANDBOX"
  version: number; // 2

  countryCode: string;
  currencyCode: string;

  merchant: YandexMerchantInfo;
  order?: any;
  paymentMethods: YandexPaymentMethods[];
  requiredFields?: {
    billingContact: {
      email: boolean;
    };
  };
  recurringOptions?: RecurringOptions;
}

export interface YandexCallBackMethods {
  onProcess: any;
  onAbort: any;
  onError: any;
}

export class YandexPayApi {
  private static _emitter = mitt();
  private static _yandexPaySession: any;
  static runtimeConfiguration: DomainConfiguration;

  public static setDomainConfiguration(config: DomainConfiguration) {
    this.runtimeConfiguration = config;
  }

  public static get emitter(): Emitter<any> {
    return this._emitter;
  }

  public static get paySession() {
    return this._yandexPaySession;
  }

  public static set paySession(session: any) {
    this._yandexPaySession = session;
  }

  public static async createSession(
    amount: number,
    invoiceId?: string,
    publicId?: string,
    currencyCode?: string,
    countryCode?: string,
    requireEmail?: boolean
  ): Promise<any> {
    Logger.LogInfo("createSession method");

    if (!publicId) {
      Logger.LogInfo("reject", publicId);
      return Promise.reject("should add publicId or paymentMethods");
    }

    const paymentData = YandexPayApi.createYandexPaymentRequest(
      amount,
      publicId,
      String(currencyCode),
      countryCode,
      invoiceId,
      requireEmail
    );

    Logger.LogInfo("paymentData", paymentData);

    const callbacks: YandexCallBackMethods = {
      onProcess: (event: any) => this.emitter.emit("onProcess", event),
      onError: async (event: any) => this.emitter.emit("onError", event),
      onAbort: async (event: any) => this.emitter.emit("onAbort", event),
    };

    Logger.LogInfo("pre create");

    let session;

    try {
      session = await YandexPayApi.createSessionWithTimeout(
        paymentData,
        callbacks
      );
      Logger.LogInfo("Yandex session", session);
    } catch (error) {
      Logger.LogInfo("Yandex session catch", error);
      session = null;
    }

    return session;
  }

  public static async createSessionWithTimeout(
    paymentData: YandexPaymentData,
    callbacks: YandexCallBackMethods
  ) {
    return new Promise((resolve, reject) => {
      const yandexTimeoutId = setTimeout(() => {
        Logger.LogInfo("Yandex timeout reject");
        clearTimeout(yandexTimeoutId);
        reject();
      }, 1000);
      YaPay.createSession(paymentData, callbacks).then((result: any) => {
        clearTimeout(yandexTimeoutId);
        resolve(result);
      });
    });
  }

  public static async mountButton(
    el: HTMLElement,
    sessionSata: {
      orderNumber: string;
      countryCode: string;
      currencyCode: string;
      publicId: string;
      amount: number;
    }
  ) {
    Logger.LogInfo("MOUNT BUTTON", this.paySession);
    if (this.paySession) {
      Logger.LogInfo("yandex session is created", this.paySession);
      if (this.paySession.payment.buttons.length === 0) {
        Logger.LogInfo("mount button", el);
        try {
          await this.paySession.mountButton(el, {
            type: YaPay.ButtonType.Pay,
            theme: YaPay.ButtonTheme.Black,
            width: YaPay.ButtonWidth.Max,
          });
        } catch (error) {
          Logger.LogInfo("catch yandex 1", error);
          await this.mountButton(el, sessionSata);
        }
      }
    } else {
      Logger.LogInfo("create session");
      try {
        this.paySession = await YandexPayApi.createSession(
          sessionSata.amount,
          sessionSata.orderNumber,
          sessionSata.publicId,
          sessionSata.currencyCode,
          sessionSata.countryCode
        );
      } catch (error) {
        Logger.LogInfo("catch yandex 2", error);
      } finally {
        Logger.LogInfo("Yandex Pay Finally");
        await this.mountButton(el, sessionSata);
      }
    }
  }

  public static async reinitializeYandexPaySession(el: HTMLElement) {
    const callback = this.paySession.options;
    const paymentData = {
      ...this.paySession.payment.sheet,
      env: this.runtimeConfiguration.payments.yandexPay.paymentEnv!,
    };

    this.dropSession(el);
    this.paySession = await YaPay.createSession(paymentData, callback);
  }

  public static dropSession(el: HTMLElement) {
    Logger.LogInfo("drop session", this.paySession);
    if (this.paySession) {
      Logger.LogInfo("unmount button", el);
      try {
        this.paySession.unmountButton(el);
        this.paySession.destroy();
        this.paySession = null;
      } catch (error) {
        Logger.LogError("Error of yandex", error);
      }
    }
  }

  public static async isYandexPayAvailable(): Promise<boolean> {
    try {
      await YandexPayApi.loadYandexPayLib();
    } catch {
      return Promise.resolve(false);
    }

    return Promise.resolve(true);
  }

  public static async loadYandexPayLib(): Promise<void> {
    if ("YaPay" in window) {
      return Promise.resolve();
    }

    return Utils.loadScript("https://pay.yandex.ru/sdk/v1/pay.js");
  }

  private static createYandexPaymentRequest(
    amount: number,
    publicId: string,
    currencyCode: string,
    countryCode: string = YaPay.CountryCode.Ru,
    invoiceId?: string,
    requireEmail?: boolean
  ): YandexPaymentData {
    const orderId = invoiceId || Utils.generateUuid();

    Logger.LogInfo("orderId", orderId);
    Logger.LogInfo("amount", amount);

    return {
      env: this.runtimeConfiguration.payments.yandexPay.paymentEnv,
      version: 2,
      countryCode,
      currencyCode,
      merchant: {
        id: this.runtimeConfiguration.payments.yandexPay.merchantId,
        name: "cloudpayments",
      },
      order: {
        id: String(orderId),
        total: {
          amount: String(amount),
        },
      },
      requiredFields: {
        billingContact: { email: !!requireEmail },
      },
      paymentMethods: [
        {
          type: YaPay.PaymentMethodType.Card,
          gateway: "cloudpayments",
          gatewayMerchantId: publicId,
          allowedAuthMethods: [YaPay.AllowedAuthMethod.PanOnly],
          allowedCardNetworks: Object.values(YaPay.AllowedCardNetwork),
        },
      ],
    };
  }
}
