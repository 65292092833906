<template>
  <div
    class="wrap"
    v-bind:class="{
      transparent: transparent,
    }"
  >
    <div class="icon" v-if="!transparent">
      <svg
        width="160"
        height="161"
        viewBox="0 0 160 161"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M72.4733 44.0599C73.156 46.1609 72.0061 48.4175 69.9051 49.1002C64.1755 50.9618 59.0723 54.3717 55.1598 58.9527C51.2472 63.5338 48.6776 69.1075 47.7352 75.0578C46.7928 81.0081 47.5142 87.1031 49.8196 92.669C52.1251 98.2349 55.9248 103.055 60.7987 106.596C65.6726 110.137 71.4308 112.261 77.4367 112.734C83.4426 113.207 89.4622 112.009 94.8301 109.274C100.198 106.539 104.705 102.373 107.853 97.2363C111 92.0996 112.666 86.1925 112.666 80.168C112.666 77.9589 114.457 76.168 116.666 76.168C118.875 76.168 120.666 77.9589 120.666 80.168C120.666 87.6679 118.592 95.0216 114.674 101.416C110.755 107.811 105.144 112.997 98.462 116.402C91.7796 119.807 84.2857 121.298 76.809 120.709C69.3323 120.121 62.1639 117.476 56.0964 113.068C50.0289 108.66 45.2987 102.659 42.4286 95.7305C39.5585 88.8015 38.6605 81.2139 39.8337 73.8063C41.0069 66.3988 44.2058 59.4601 49.0765 53.7571C53.9473 48.0542 60.3002 43.8093 67.433 41.4917C69.534 40.8091 71.7906 41.9589 72.4733 44.0599Z"
          fill="#2E71FC"
        />
      </svg>
    </div>
    <div class="text" v-if="!transparent && showText">
      <span>{{ $t("components.loaderWrap.paymentProcessing") }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "LoaderWrap",
  props: {
    transparent: Boolean,
    showText: Boolean,
  },
});
</script>

<style scoped lang="scss">
.wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1000;

  .text {
    color: var(--default-primary-text);
  }

  &.transparent {
    background: transparent !important;
  }
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 160px;
  animation-name: rotation;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  z-index: 1000;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.minimal,
.neu-classic {
  .wrap {
    background: var(--loader-background);
  }
}

.minimal {
  svg {
    path {
      fill: #4297df;
    }
  }
}
</style>
