/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum CloudPayments_Processing_Contracts_Enums_CurrencyCode {
    '_0' = 0,
    '_1' = 1,
    '_2' = 2,
    '_3' = 3,
    '_4' = 4,
    '_5' = 5,
    '_6' = 6,
    '_7' = 7,
    '_8' = 8,
    '_9' = 9,
    '_10' = 10,
    '_11' = 11,
    '_12' = 12,
    '_13' = 13,
    '_14' = 14,
    '_15' = 15,
    '_16' = 16,
    '_17' = 17,
    '_18' = 18,
    '_19' = 19,
    '_20' = 20,
    '_21' = 21,
    '_22' = 22,
    '_23' = 23,
    '_24' = 24,
    '_25' = 25,
    '_26' = 26,
    '_27' = 27,
    '_28' = 28,
    '_29' = 29,
    '_30' = 30,
    '_31' = 31,
    '_32' = 32,
    '_33' = 33,
    '_34' = 34,
    '_35' = 35,
    '_36' = 36,
    '_37' = 37,
    '_38' = 38,
    '_39' = 39,
    '_40' = 40,
    '_41' = 41,
    '_42' = 42,
    '_43' = 43,
    '_44' = 44,
    '_45' = 45,
    '_46' = 46,
    '_47' = 47,
    '_48' = 48,
    '_49' = 49,
    '_50' = 50,
    '_51' = 51,
    '_52' = 52,
    '_53' = 53,
    '_54' = 54,
    '_55' = 55,
    '_56' = 56,
    '_57' = 57,
    '_58' = 58,
    '_59' = 59,
    '_60' = 60,
    '_61' = 61,
    '_62' = 62,
    '_63' = 63,
    '_64' = 64,
    '_65' = 65,
    '_66' = 66,
    '_67' = 67,
    '_68' = 68,
    '_69' = 69,
    '_70' = 70,
    '_71' = 71,
    '_72' = 72,
    '_73' = 73,
    '_74' = 74,
    '_75' = 75,
    '_76' = 76,
    '_77' = 77,
    '_78' = 78,
    '_79' = 79,
    '_80' = 80,
    '_81' = 81,
    '_82' = 82,
    '_83' = 83,
    '_84' = 84,
    '_85' = 85,
    '_86' = 86,
    '_87' = 87,
    '_88' = 88,
    '_89' = 89,
    '_90' = 90,
    '_91' = 91,
    '_92' = 92,
    '_93' = 93,
    '_94' = 94,
    '_95' = 95,
    '_96' = 96,
    '_97' = 97,
    '_98' = 98,
    '_99' = 99,
    '_100' = 100,
    '_101' = 101,
    '_102' = 102,
    '_103' = 103,
    '_104' = 104,
    '_105' = 105,
    '_106' = 106,
    '_107' = 107,
    '_108' = 108,
    '_109' = 109,
    '_110' = 110,
    '_111' = 111,
    '_112' = 112,
    '_113' = 113,
    '_114' = 114,
    '_115' = 115,
    '_116' = 116,
    '_117' = 117,
    '_118' = 118,
    '_119' = 119,
    '_120' = 120,
    '_121' = 121,
    '_122' = 122,
    '_123' = 123,
    '_124' = 124,
    '_125' = 125,
    '_126' = 126,
    '_127' = 127,
    '_128' = 128,
    '_129' = 129,
    '_130' = 130,
    '_131' = 131,
    '_132' = 132,
    '_133' = 133,
    '_134' = 134,
    '_135' = 135,
    '_136' = 136,
    '_137' = 137,
    '_138' = 138,
    '_139' = 139,
    '_140' = 140,
    '_141' = 141,
    '_142' = 142,
    '_143' = 143,
    '_144' = 144,
    '_145' = 145,
    '_146' = 146,
    '_147' = 147,
    '_148' = 148,
    '_149' = 149,
    '_150' = 150,
    '_151' = 151,
    '_152' = 152,
}
