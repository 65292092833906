<template src="./InstallmentModal.html"></template>

<script lang="ts">
import { defineComponent, inject, PropType, ref } from "vue";
import {
  Button,
  Header,
  ModalService,
  Slider,
} from "@cloudpayments/vue-ui-kit";
import { Utils } from "@cloudpayments/vue-utils";
import { DomainConfigurationKey } from "@src/injections";

export default defineComponent({
  name: "InstallmentModal",
  components: {
    Header,
    Slider,
    Button,
  },
  props: {
    promoCodes: Object as PropType<Array<string>>,
    brand: String,
    amount: Number,
    title: String,
    currencySign: String,
  },
  setup(props) {
    const modal = inject<ModalService>("ModalService");
    const runtimeConfiguration = inject(DomainConfigurationKey);
    const getMonthCount = (promoCode: string) => {
      return +promoCode.split("_")[3];
    };

    const sortedPromoCodes = props.promoCodes?.length
      ? [...props.promoCodes].sort(function (a, b) {
          return getMonthCount(a) - getMonthCount(b);
        })
      : [];

    const currentIndexSlider = ref(
      (Math.ceil(sortedPromoCodes.length / 2) - 1).toString()
    );

    function goToInstallment() {
      modal?.closeModal(sortedPromoCodes[+currentIndexSlider.value]);
    }

    function goBack() {
      modal?.closeModal();
    }

    function updateCurrentInstallmentValue(newIndex: string) {
      currentIndexSlider.value = newIndex;
    }

    const getFormattedAmount = (amount: number) => {
      return Utils.getFormattedAmountWithCurrency({
        amount,
        currencyCode: props.currencySign,
        firstDelimiter: runtimeConfiguration?.amountSettings?.firstDelimiter,
        secondDelimiter: runtimeConfiguration?.amountSettings?.secondDelimiter,
        locale: runtimeConfiguration?.amountSettings?.locale,
      });
    };

    return {
      currentIndexSlider,
      updateCurrentInstallmentValue,
      goToInstallment,
      getFormattedAmount,
      goBack,
      steps: sortedPromoCodes.map((promoCode) => getMonthCount(promoCode)),
    };
  },
});
</script>

<style src="./InstallmentModal.scss" lang="scss" scoped></style>
