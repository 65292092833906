import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, withCtx as _withCtx, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0a9a2d4c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wait" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { class: "status-text" }

export function render(_ctx, _cache) {
  const _component_LoaderIcon = _resolveComponent("LoaderIcon")
  const _component_Icon = _resolveComponent("Icon")
  const _component_Text = _resolveComponent("Text")
  const _component_Button = _resolveComponent("Button")
  const _component_ReloadIcon = _resolveComponent("ReloadIcon")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_LoaderIcon, {
      class: "loading-icon",
      type: "medium"
    }),
    _createElementVNode("div", _hoisted_2, [
      (!_ctx.isSom)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.$t('modals.waitBottomSheet.waitingResponse')), 1))
        : _createCommentVNode("", true),
      (_ctx.isSom)
        ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.$t('modals.waitBottomSheet.waitingResponseBank')), 1))
        : _createCommentVNode("", true),
      (_ctx.icon)
        ? (_openBlock(), _createBlock(_component_Icon, {
            key: 2,
            height: "26",
            width: "70"
          }, {
            default: _withCtx(() => [
              (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.icon)))
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_Text, {
      type: "p3",
      class: "info"
    }, {
      default: _withCtx(() => [
        (!_ctx.isSom)
          ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.$t('modals.waitBottomSheet.info')), 1))
          : _createCommentVNode("", true),
        (_ctx.isSom)
          ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.$t('modals.waitBottomSheet.info2')), 1))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_component_Button, {
      class: "return-button",
      type: "secondary",
      onClick: _cache[0] || (_cache[0] = $event => (_ctx.backToPaymentMethods()))
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('modals.waitBottomSheet.anotherMethod')), 1)
      ]),
      _: 1
    }),
    (_ctx.reloadStatus)
      ? (_openBlock(), _createBlock(_component_Button, {
          key: 0,
          type: "tertiary",
          onClick: _ctx.onReloadStatus
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ReloadIcon),
            _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t('modals.waitBottomSheet.refreshStatus')), 1)
          ]),
          _: 1
        }, 8, ["onClick"]))
      : _createCommentVNode("", true)
  ]))
}