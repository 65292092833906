<template src="./Qr.html"></template>

<script lang="ts">
import {
  defineComponent,
  inject,
  onMounted,
  onUnmounted,
  PropType,
  reactive,
  ref,
} from "vue";

import {
  Alert,
  AttentionIcon,
  Button,
  Icon,
  LoaderIcon,
  LoaderSpinner,
  ModalService,
  SberPayLogo,
  Sbp3Icon,
  Text,
  TinkoffPayIconBig,
} from "@cloudpayments/vue-ui-kit";

import { QRImageResponse, QRResponse } from "@cloudpayments/api-client";
import { AppService } from "@src/services/AppService";
import {
  SbpApi,
  SbpResultStatus,
} from "@src/services/externalApiServices/SBP/sbpApi";
import { IterableTask } from "@cloudpayments/vue-utils";
import { QrMode } from "@src/enums";
import { AmplitudeEvents } from "@src/contracts/AmplitudeEvents";
import { TinkoffPayApi } from "@src/services/externalApiServices/TinkoffPay/tinkoffPayApi";
import { TransactionResultStatus } from "@src/contracts/TransactionResultStatus";
import { SberPayApi } from "@src/services/externalApiServices/SberPay/sberPayApi";
import { MonitoringServiceKey } from "@src/injections";

export default defineComponent({
  name: "Qr",
  components: {
    LoaderSpinner,
    Alert,
    LoaderIcon,
    Button,
    Text,
    Icon,
    Sbp3Icon,
    AttentionIcon,
    SberPayLogo,
    TinkoffPayIconBig,
  },
  props: {
    mode: String as PropType<QrMode>,
    email: String,
  },
  emits: ["onResult"],
  setup(props, { emit }) {
    const qrState = reactive({
      isLoading: true,
      showCode: false,
      status: SbpResultStatus.Wait,
    });
    const appService = inject<AppService>("AppService")!;
    const monitoring = inject(MonitoringServiceKey);
    const modal = inject<ModalService>("ModalService");
    const sbpApi = inject<SbpApi>("SbpApi");
    const tinkoffPayApi = inject<TinkoffPayApi>("TinkoffPayApi");
    const sberPayApi = inject<SberPayApi>("SberPayApi");

    const qrImage = ref<string>();
    const waitEnabled = ref(true);

    const transactionId = ref<number>();
    let waitTask:
      | IterableTask<SbpResultStatus | TransactionResultStatus>
      | undefined;

    onMounted(() => {
      generateQRImage();
      if (waitTask) {
        waitTask.terminate();
      }
    });

    onUnmounted(() => {
      waitEnabled.value = false;
      if (waitTask) {
        waitTask.terminate();
      }
    });

    async function generateQRImage() {
      qrState.isLoading = true;
      try {
        let qrImageResponse: QRResponse<QRImageResponse> | undefined;

        if (props.mode == QrMode.SBP) {
          qrImageResponse = await sbpApi!.createSBPQRImage({
            publicOrderId: appService?.getOrderOptions().publicOrderId,
            email: appService.email.value,
            saveCard: appService.orderFormOptions.value.saveCard,
          });
        }

        if (props.mode === QrMode.TinkoffPay) {
          qrImageResponse = await tinkoffPayApi?.createTinkoffPayQrImage({
            publicOrderId: appService?.getOrderOptions().publicOrderId,
            email: appService.email.value,
            saveCard: appService.orderFormOptions.value.saveCard,
          });
        }

        if (props.mode === QrMode.SberPay) {
          qrImageResponse = await sberPayApi?.createSberPayQrImage({
            publicOrderId: appService?.getOrderOptions().publicOrderId,
            email: appService.email.value,
            saveCard: appService.orderFormOptions.value.saveCard,
          });
        }

        const isError =
          !qrImageResponse?.Model?.QrImage || !qrImageResponse?.Success;

        if (qrImageResponse && isError) {
          qrImageResponse.Success = false;
        }

        if (qrImageResponse?.Success) {
          transactionId.value = (await qrImageResponse).Model.TransactionId;
          qrImage.value = (await qrImageResponse).Model.QrImage;
          if (qrImage.value) {
            qrState.showCode = true;
          }
          qrState.isLoading = false;
          setWaitTask(qrImageResponse);
          monitoring?.sendAmplitudeEvent(AmplitudeEvents.ShowQR, {
            method: props.mode,
          });
        } else {
          modal?.closeModal({
            status: TransactionResultStatus.Fail,
            errorCode: qrImageResponse?.ErrorCode,
          });
        }
      } catch {
        modal?.closeModal({
          status: TransactionResultStatus.Fail,
        });
      }
    }

    function setWaitTask(sbpLinkResponse: QRResponse<QRImageResponse>) {
      if (waitTask) {
        waitTask.terminate();
      }
      waitTask = new IterableTask<SbpResultStatus | TransactionResultStatus>(
        async () => {
          if (props.mode === QrMode.SBP) {
            return await sbpApi!
              .waitSBPStatus({
                terminalPublicId: appService.getOrderOptions().publicId,
                transactionId: sbpLinkResponse.Model.TransactionId,
              })
              .then((res) => {
                qrState.status = res;
                return res;
              });
          }

          if (props.mode === QrMode.TinkoffPay) {
            return await tinkoffPayApi!
              .waitTinkoffPayStatus({
                terminalPublicId: appService.getOrderOptions().publicId,
                transactionId: sbpLinkResponse.Model.TransactionId,
              })
              .then((res) => {
                return res;
              });
          }

          if (props.mode === QrMode.SberPay) {
            return await sberPayApi!
              .waitSberPayStatus({
                terminalPublicId: appService.getOrderOptions().publicId,
                transactionId: sbpLinkResponse.Model.TransactionId,
              })
              .then((res) => {
                return res;
              });
          }
        },
        (res: SbpResultStatus | TransactionResultStatus) => {
          if (props.mode === QrMode.SBP) {
            return (
              res === SbpResultStatus.Wait ||
              res === SbpResultStatus.AwaitingAuthentication
            );
          }

          return res === TransactionResultStatus.Wait;
        }
      );
      waitTask
        .then((result) => {
          toResult(result);
        })
        .catch((e) => {
          qrState.showCode = false;
        });
    }

    function toResult(
      result: SbpResultStatus | TransactionResultStatus,
      errorCode?: number
    ) {
      modal?.closeModal({
        status: result,
        errorCode,
      });
      emit("onResult", result);
    }

    function toMain() {
      modal?.closeModal();
    }

    function getQrImage() {
      if (props.mode == QrMode.TinkoffPay || props.mode == QrMode.SberPay) {
        return `data:image/svg+xml;base64,${qrImage.value}`;
      }
      return `data:image/jpeg;base64,${qrImage.value}`;
    }

    return {
      qrState,
      qrImage,
      getQrImage,
      generateQRImage,
      toMain,
      QrMode,
    };
  },
});
</script>

<style src="./Qr.scss" lang="scss" scoped></style>
