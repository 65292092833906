<div class="home">
  <OrderHeader
          v-if="order"
          :isCharity="isCharity"
          :showOrderId="true"
          :title="!isCharity ? $t('app.header.title.order') : $t('app.header.title.charity')"
          :failPaymentMethod="failPaymentMethod"
          :successPaymentMethod="successPaymentMethod"
          :order="order"
          :transaction-info="transactionInfo"
  >
  <template v-slot:transactionInfo>
        <div v-if="showTransactionDetails && transactionInfo && transactionInfo.IsCard" style="width: 100%">
          <div class="details__wrap1" :style="isOpenDetails ? {'grid-template-rows': '1fr'} : {}">
          <div class="details__wrap2">
            <div style="flex: 1">
              <div class="details__label">{{ $t("transactionDetails.paymentMethod") }}</div>
              <div class="details__value" style="display: flex">
                <CardIcon v-if="transactionInfo.CardType" :card-name="transactionInfo.CardType" />
                <div v-if="transactionInfo.LastFour"> •••• {{ transactionInfo.LastFour }}</div>
              </div>
              <div class="details__label">{{ $t("transactionDetails.authCode") }}</div>
              <div class="details__value">{{ transactionInfo.AuthCode || '—' }}</div>
              <div class="details__label">{{ $t("transactionDetails.transactionId") }}</div>
              <div class="details__value">{{ transactionInfo.TransactionId || '—' }}</div>
            </div>
            <div style="flex: 1">
              <div class="details__label">{{ $t("transactionDetails.authDate") }}</div>
              <div class="details__value">{{ localizedAuthDate || '—' }}</div>
              <div class="details__label">{{ $t("transactionDetails.affiliationId") }}</div>
              <div class="details__value">{{ transactionInfo.AffiliationId || '—' }}</div>
              <div class="details__label">{{ $t("transactionDetails.reference") }}</div>
              <div class="details__value">{{ transactionInfo.Reference || '—' }}</div>
            </div>
          </div>
        </div>
          <Button type="secondary" class="button_show-details" @click="isOpenDetails = !isOpenDetails">
            <span>{{ $t("transactionDetails.info") }}</span>
            <DownBlueIcon
              style="margin-left: 10px; transition: transform .2s ease-in;"
              :style="isOpenDetails ? {transform: 'rotate(180deg)'} : {}"
            />
          </Button>
        </div>
</template>
  </OrderHeader>
  <PaymentProcess
          v-if="showProcess"
          @onSuccess="onSuccess"
          @onFail="onFail"
          @onChange="onChange"
          :order="order"
  />
  <OrderContents
          v-if="showContent"
          :order="order"
          :isCharity="isCharity"
  />
</div>
