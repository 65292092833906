/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OrdersAPI_DTO_API_Requests_SubscriptionPayRequest } from '../models/OrdersAPI_DTO_API_Requests_SubscriptionPayRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SubscriptionService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param subscriptionId
     * @returns any Success
     * @throws ApiError
     */
    public getSubscription(
        subscriptionId?: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/subscription',
            query: {
                'subscriptionId': subscriptionId,
            },
        });
    }

    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public postSubscriptionPay(
        requestBody?: OrdersAPI_DTO_API_Requests_SubscriptionPayRequest,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/subscription/pay',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
