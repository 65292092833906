<template src="./SbpProcess.html"></template>

<script lang="ts">
import { defineComponent, inject, reactive, ref, computed } from "vue";

import {
  AttentionIcon,
  BottomBar,
  Button,
  Icon,
  LoaderService,
  ModalService,
  Sbp2Icon,
  SbpIcon,
  Text,
  LoaderNewIcon,
  TextField,
} from "@cloudpayments/vue-ui-kit";

import { QRLinkResponse, QRResponse } from "@cloudpayments/api-client";
import { AppService } from "@src/services/AppService";
import {
  SbpApi,
  SbpResultStatus,
} from "@src/services/externalApiServices/SBP/sbpApi";
import { delay, isPageVisible, pageBecomeVisible } from "@src/common/Utils";
import { DeviceDetector, Logger } from "@cloudpayments/vue-utils";
import Qr from "@src/components/SbpProcess/Qr/Qr.vue";
import { QrMode } from "@src/enums";
import { BankInfo } from "@cloudpayments/api-client/src/contracts/QR/BanksResponse";
import { SearchIcon } from "@src/icons/AsyncIcons";
import { Monitoring } from "@cp-common/frontend-monitoring";
import { MonitoringServiceKey } from "@src/injections";
import { IFrontendOrdersSbpBankClick } from "@src/metrics/IFrontendOrdersSbpBankClick";
import { MonitoringMetricsNames } from "@src/enums/MonitoringMetricsNames";

export default defineComponent({
  name: "SbpProcess",
  components: {
    Button,
    Text,
    Icon,
    SbpIcon,
    Sbp2Icon,
    AttentionIcon,
    BottomBar,
    LoaderNewIcon,
    TextField,
    SearchIcon,
  },
  props: {
    isLoading: Boolean,
  },
  emits: ["onResult", "onQrPay"],
  setup(_, { emit }) {
    const appService = inject<AppService>("AppService");
    const loader = inject<LoaderService>("LoaderService");
    const modal = inject<ModalService>("ModalService");
    const sbpApi = inject<SbpApi>("SbpApi");
    const monitoring = inject<Monitoring>(MonitoringServiceKey);
    const showBottomBar = ref(false);
    const showBanks = ref();
    const textForSearch = ref("");
    const banks = ref<BankInfo[]>([]);
    const filteredBanks = computed(() =>
      !textForSearch.value
        ? banks.value
        : banks.value?.filter(
            (bank) =>
              bank.bankName
                .toLowerCase()
                .indexOf(textForSearch.value.trim().toLowerCase()) !== -1
          )
    );
    const qrLink = ref<string>();
    const transactionId = ref<number>();
    const providerQrId = ref();

    const stateLoading = reactive({
      loadingApp: false,
      loadingRequest: false,
      checkPayRequest: false,
    });

    function sendSbpStatistic(bankName: string) {
      monitoring?.sendStatistEvent("events.clicks.sbpBank", {
        name: bankName,
        search: !!textForSearch.value,
      });

      monitoring?.sendMetric<IFrontendOrdersSbpBankClick>({
        metricName: MonitoringMetricsNames.SBP_CLICK_BANK,
        tagValues: {
          Name: bankName,
          Search: !!textForSearch.value,
        },
      });
    }

    async function onSbpPay(): Promise<void> {
      if (stateLoading.loadingApp) {
        return;
      }

      stateLoading.loadingApp = true;

      const deviceDetector = new DeviceDetector();
      if (deviceDetector.isMobileSbpSpecific) {
        await generateSbpLink();
      } else {
        emit("onQrPay");
        stateLoading.loadingApp = false;

        const modalResult = await modal?.openModal({
          component: Qr,
          model: { mode: QrMode.SBP },
        });

        if (modalResult) {
          emit("onResult", modalResult);
        }
      }
    }

    async function checkPayStatus() {
      if (stateLoading.checkPayRequest) {
        return;
      }
      stateLoading.checkPayRequest = true;
      await afterOpenBank(true, true);
      stateLoading.checkPayRequest = false;
    }

    async function afterOpenBank(withDelay = false, singleLaunch = false) {
      if (withDelay) {
        await delay(500);
      }

      if (!isPageVisible()) {
        Logger.LogInfo("Page not visible, waiting for become active");
        await pageBecomeVisible();
      }

      if (transactionId.value) {
        const waitStatusResult = await sbpApi!.waitSBPStatus({
          terminalPublicId: appService!.getOrderOptions().publicId,
          transactionId: transactionId.value,
        });

        Logger.LogInfo("WaitStatus result", waitStatusResult);

        if (isPageVisible()) {
          handleWaitStatusResult(waitStatusResult, singleLaunch);
        } else {
          Logger.LogInfo(
            "Page not visible, waiting for become active to handle result"
          );
          await pageBecomeVisible();
          handleWaitStatusResult(waitStatusResult, singleLaunch);
        }
      }

      if (!qrLink.value && !transactionId.value) {
        onModalClose();
      }
    }

    function handleWaitStatusResult(
      result: SbpResultStatus,
      singleLaunch = false
    ) {
      if (!showBottomBar.value || !appService?.getOrderOptions()) {
        return;
      }

      if (result !== SbpResultStatus.Wait) {
        emit("onResult", result);
        onModalClose();
      } else if (!singleLaunch) {
        afterOpenBank();
      }
    }

    async function generateSbpLink() {
      if (stateLoading.loadingRequest) {
        return;
      }

      loader?.showLoader(5);

      stateLoading.loadingRequest = true;

      try {
        const sbpLinkResponse: QRResponse<QRLinkResponse> =
          await sbpApi!.createSBPLink({
            publicOrderId: appService?.getOrderOptions().publicOrderId,
            email: appService!.email.value,
          });

        const isError =
          !sbpLinkResponse?.Model?.QrUrl || !sbpLinkResponse?.Success;

        if (sbpLinkResponse && isError) {
          sbpLinkResponse.Success = false;
          if (sbpLinkResponse.ErrorCode) {
            emit("onResult", {
              status: SbpResultStatus.Fail,
              errorCode: sbpLinkResponse.ErrorCode,
            });
          }

          return;
        }

        if (sbpLinkResponse && sbpLinkResponse?.Success) {
          qrLink.value = sbpLinkResponse.Model.QrUrl.replace("https", "");
          transactionId.value = sbpLinkResponse.Model.TransactionId;
          providerQrId.value = sbpLinkResponse.Model.ProviderQrId;
        } else {
          onModalClose();
        }

        banks.value = sbpLinkResponse.Model.Banks?.dictionary as BankInfo[];
        showBottomBar.value = true;

        if (banks.value?.length) {
          showBanks.value = true;
        }
      } catch {
        onModalClose();
      } finally {
        loader?.hideLoader();
        stateLoading.loadingRequest = false;
      }
    }

    function onModalClose() {
      showBanks.value = false;
      showBottomBar.value = false;
      loader?.hideLoader();
      stateLoading.loadingRequest = false;
      stateLoading.loadingApp = false;
    }

    return {
      qrLink,
      banks,
      showBanks,
      showBottomBar,
      onSbpPay,
      onModalClose,
      afterOpenBank,
      checkPayStatus,
      generateSbpLink,
      stateLoading,
      textForSearch,
      filteredBanks,
      sendSbpStatistic,
      providerQrId,
    };
  },
});
</script>

<style src="./SbpProcess.scss" lang="scss" scoped></style>
