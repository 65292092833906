<template src="./CardForm.html"></template>
<script lang="ts">
import {
  defineComponent,
  ref,
  onMounted,
  onDeactivated,
  inject,
  reactive,
  watch,
  PropType,
} from "vue";
import {
  CardForm as ExternalCardForm,
  CardData,
  CardFormConfiguration,
} from "@cloudpayments/vue-ui-presets";

import { minLength, required } from "@vuelidate/validators";

import { Utils, Logger, Validators, CardUtils } from "@cloudpayments/vue-utils";
import { AppService } from "@src/services/AppService";
import { LoaderService } from "@cloudpayments/vue-ui-kit";
import { DomainConfigurationKey } from "@src/injections";

export default defineComponent({
  name: "CardForm",
  components: {
    ExternalCardForm,
  },
  props: {
    amount: {
      type: Number,
      required: true,
    },
    currency: {
      type: String,
      required: true,
    },
    isCvvRequired: {
      type: Boolean,
      required: true,
      default: true,
    },
    skipExpiryValidation: {
      type: Boolean,
      required: true,
      default: true,
    },
    isForeignCardEnabled: {
      type: Boolean,
      default: false,
    },
    loading: Boolean,
    disabled: Boolean,
    isPaymentButtonHidden: {
      type: Boolean,
      default: false,
    },
    isNeedValidationBE: {
      type: Boolean,
      default: false,
    },
    defaultCardData: {
      type: Object as PropType<CardData>,
      required: false,
    },
  },
  emits: [
    "onPay",
    "onChange",
    "onChangeBinInfo",
    "onValid",
    "onBankInfoChanged",
  ],
  setup(props, { emit }) {
    const cardForm = ref<InstanceType<typeof ExternalCardForm>>();
    const appService = inject<AppService>("AppService");
    const loader = inject<LoaderService>("LoaderService");
    const runtimeConfiguration = inject(DomainConfigurationKey);
    const currencySymbol = ref();
    const formattedAmount = ref();
    const direction = ref(window.innerWidth >= 961 ? "horizontal" : "vertical");
    const requireEmail = false;
    const email = ref("email@test.com");
    const isCharity = ref();

    const cardNumber = ref();
    const cardName = ref();
    const cardBankName = ref();

    const isDate = Validators.isDate(props.skipExpiryValidation);
    const cardValidLength = Validators.isCardValidLength();
    const cardValidLuhn = Validators.isCardValidLuhn();
    const cvvValidLength = Validators.isCvvValidLength(cardNumber, cardName);

    const config: CardFormConfiguration = {
      iconGetter: (bin: string) =>
        new Promise((resolve, reject) => {
          try {
            appService
              ?.getPayment()
              ?.getBinInfo(
                bin,
                props.isNeedValidationBE ? true : undefined,
                props.isNeedValidationBE
                  ? appService?.getOrderOptions()?.terminalPublicId
                  : undefined
              )
              .then((result) => {
                emit("onChangeBinInfo", result?.Model);
                cardBankName.value = result.Model.BankName;
                state.cvvRequired = !result.Model?.HideCvvInput ?? true;
                state.cardType = (result.Model as any)?.CardType ?? null;
                resolve(result.Model);
              })
              .catch(() => {
                cardBankName.value = "";
              });
          } catch (e) {
            reject("");
            cardBankName.value = "";
          }
        }),
      fields: {
        cardNumber: {
          validator: {
            required,
            cardValidLuhn,
            cardValidLength,
          },
          showSkeleton: () => loader?.isShowSkeleton() || false,
        },
        cardExpiration: {
          validator: {
            required: required,
            isDate,
            min: minLength(7),
          },
          showSkeleton: () => loader?.isShowSkeleton() || false,
        },
        cardSecretCode: {
          validator: {
            required,
            cvvValidLength,
          },
          showSkeleton: () => loader?.isShowSkeleton() || false,
        },
        cardHolder: {
          enabled: false,
          validator: {
            required,
          },
          showSkeleton: () => loader?.isShowSkeleton() || false,
        },
      },
    };

    const state = reactive<{
      disabled: boolean;
      cvvRequired: boolean;
      cardType: string | null;
    }>({
      disabled: props.disabled,
      cvvRequired: props.isCvvRequired,
      cardType: null,
    });

    watch(
      () => props.disabled,
      (next) => {
        state.disabled = next;
      }
    );

    onMounted(() => {
      currencySymbol.value = Utils.getCurrencySymbol(props?.currency);
      formattedAmount.value = Utils.getFormattedAmountWithCurrency({
        amount: props.amount,
        currencyCode: props?.currency,
        firstDelimiter: runtimeConfiguration?.amountSettings?.firstDelimiter,
        secondDelimiter: runtimeConfiguration?.amountSettings?.secondDelimiter,
        locale: runtimeConfiguration?.amountSettings?.locale,
      });
      isCharity.value = appService?.getOrderOptions()?.terminalInfo?.IsCharity;

      window.addEventListener("resize", updateWidth);
    });

    onDeactivated(() => {
      window.removeEventListener("resize", updateWidth);
    });

    async function payByCard(cardData: CardData): Promise<void> {
      cardNumber.value = cardData.cardNumber;
      cardName.value = CardUtils.getCardType(String(cardData.cardNumber));

      if (cardNumber.value.length < 7) {
        cardBankName.value = "";
      }

      emit("onPay", {
        cardData,
        email: email.value,
        cardName: cardName.value,
        bankName: cardBankName.value,
        cvvRequired: state.cvvRequired,
      });
    }

    function onUpdateEmail(updateEmail: string): void {
      if (email.value !== updateEmail) {
        email.value = updateEmail;
      }
    }

    function updateWidth() {
      if (window.innerWidth <= 960 && direction.value === "horizontal") {
        direction.value = "vertical";
      }

      if (window.innerWidth >= 961 && direction.value === "vertical") {
        direction.value = "horizontal";
      }

      Logger.LogInfo("direction", direction.value);
    }

    function reset() {
      cardForm?.value?.reset();
    }

    function onChange(cardData: CardData) {
      cardNumber.value = cardData.cardNumber;
      cardName.value = CardUtils.getCardType(String(cardData.cardNumber));

      if (cardNumber.value.length < 7) {
        cardBankName.value = "";
      }

      emit("onChange", {
        cardData,
        email: email.value,
        cardName: cardName.value,
        bankName: cardBankName.value,
      });
    }

    return {
      cardForm,
      payByCard,
      onUpdateEmail,
      currencySymbol,
      formattedAmount,
      requireEmail,
      email,
      direction,
      isCharity,
      reset,
      state,
      onChange,
      config,
    };
  },
});
</script>
<style src="./CardForm.scss" scoped lang="scss"></style>
