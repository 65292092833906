<template src="./Subscription.html"></template>
<script lang="ts">
import {
  ComponentInternalInstance,
  computed,
  defineComponent,
  getCurrentInstance,
  inject,
  nextTick,
  onMounted,
  ref,
} from "vue";
import {
  Card,
  Header,
  LoaderService,
  Table,
  Text,
} from "@cloudpayments/vue-ui-kit";

import { PaymentMethodEnum } from "@src/contracts/PaymentForm";
import PaymentProcess from "@src/components/PaymentProcess/PaymentProcess.vue";
import OrderHeader from "@src/components/OrderHeader/OrderHeader.vue";
import OrderContents from "@src/components/OrderContents/OrderContents.vue";
import { AppService } from "@src/services/AppService";
import { useRoute, useRouter } from "vue-router";
import { AmplitudeEvents } from "@src/contracts/AmplitudeEvents";
import { Lang } from "@src/contracts/LangEnum";
import { MonitoringServiceKey } from "@src/injections";

export default defineComponent({
  name: "Subscription",
  components: {
    Card,
    Header,
    Text,
    Table,
    PaymentProcess,
    OrderContents,
    OrderHeader,
  },
  setup() {
    const loader = inject<LoaderService>("LoaderService");
    const appService = inject<AppService>("AppService");
    const monitoring = inject(MonitoringServiceKey);

    let app: ComponentInternalInstance | null;
    const isSubscriptionLoaded = ref(false);
    const showProcess = ref(true);
    const successPaymentMethod = ref<PaymentMethodEnum | null>(null);
    const failPaymentMethod = ref<PaymentMethodEnum | null>(null);
    const isCharity = ref();
    const route = useRoute();
    const router = useRouter();

    const subscription = ref();

    onMounted(async () => {
      app = getCurrentInstance();
      try {
        await getSubscription();
      } catch {
        await router.push({
          name: "ErrorPage",
        });
      }
    });

    const showContent = computed(() => {
      return (
        !isSubscriptionLoaded.value ||
        (isSubscriptionLoaded.value && subscription.value.items?.items != null)
      );
    });

    async function getSubscription() {
      const id = String(route?.params?.id);
      subscription.value = await appService?.getSubscription(id);
      isSubscriptionLoaded.value = !!id && !!subscription.value;
      isCharity.value = subscription.value?.terminalInfo.IsCharity;
      showProcess.value = true;

      await changeLocale(subscription.value?.cultureName);

      loader?.hideLoader();

      if (subscription.value) {
        document.title = subscription.value.description;

        monitoring?.sendAmplitudeEvent(AmplitudeEvents.OrdersOpen, {
          AccountId: subscription.value.accountId,
          UserEmail: subscription.value.email,
          TerminalMode: subscription.value.terminalMode,
          PublicId: subscription.value.terminalPublicId,
          TerminalName: subscription.value.terminalName,
          Amount: subscription.value.amount,
          Currency: subscription.value.currency,
          InvoiceId: subscription.value.orderNumber,
          URL: window.location.origin,
        });
      }
    }

    async function changeLocale(locale: string): Promise<void> {
      isSubscriptionLoaded.value = false;
      const lowerCasedLocale = locale.toLowerCase();
      let lang = null;
      if (
        lowerCasedLocale.includes("ru") ||
        lowerCasedLocale == "kk" ||
        lowerCasedLocale == "uz"
      ) {
        lang = Lang.Ru;
      } else if (lowerCasedLocale == "kk-kz") {
        lang = Lang.Kz;
      } else if (lowerCasedLocale == "es-mx") {
        lang = Lang.Es;
      } else {
        lang = Lang.En;
      }
      if (app) {
        const changeLocale =
          app.appContext.config.globalProperties.changeLocale;
        if (changeLocale) {
          await changeLocale(lang);
          nextTick(() => {
            isSubscriptionLoaded.value = true;
          });
        }
      }
    }

    function onSuccess(paymentMethod: PaymentMethodEnum) {
      showProcess.value = false;
      successPaymentMethod.value = paymentMethod;
      failPaymentMethod.value = null;
      window.scrollTo(0, 0);
      loader?.hideLoader();
      monitoring?.sendAmplitudeEvent(AmplitudeEvents.OnSuccessPage);
    }

    function onFail(paymentMethod: PaymentMethodEnum): void {
      failPaymentMethod.value = paymentMethod;
      successPaymentMethod.value = null;
      window.scrollTo(0, 0);
      loader?.hideLoader();
      monitoring?.sendAmplitudeEvent(AmplitudeEvents.OnErrorPage);
    }

    function onChange(): void {
      successPaymentMethod.value = null;
      failPaymentMethod.value = null;
    }

    return {
      successPaymentMethod,
      showProcess,
      isCharity,
      onSuccess,
      onFail,
      onChange,
      subscription,
      showContent,
    };
  },
});
</script>
<style src="./Subscription.scss" scoped lang="scss"></style>
