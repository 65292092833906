<Modal />
<div class="app">
  <Notification type="error" />
  <div class="checkout">
    <LoaderWrap v-if="loader?.isShowLoader()" :transparent="loader?.isShowTransparent()"
                :showText="loader?.withText()" />
    <router-view />
  </div>
  <div class="cloud" v-if="showContent">
    <SecuredIcon/>
  </div>

</div>
