<template src="./Spei.html"></template>

<script lang="ts">
import {
  defineComponent,
  inject,
  onMounted,
  onUnmounted,
  PropType,
  reactive,
  ref,
} from "vue";
import {
  Alert,
  Button,
  Card,
  Icon,
  NotificationService,
  TextField,
} from "@cloudpayments/vue-ui-kit";
import {
  CopySmallIcon,
  LeftArrowIcon,
  TelegramIcon,
} from "@src/icons/AsyncIcons";
import { CreateAltPayOrderResponse } from "@src/services/api/models/CreateAltPayOrderResponse";
import {
  getFormattedAmount,
  IterableTask,
  Logger,
  NotificationType,
  Timer,
} from "@cloudpayments/vue-utils";
import { AppService } from "@src/services/AppService";
import { clipboard } from "@src/common/Utils";
import { AltPayApi } from "@src/services/externalApiServices/TinkoffPay/altPayApi";
import { TransactionResultStatus } from "@src/contracts/TransactionResultStatus";
import { SessionStorageService } from "@src/services/SessionStorageService";
import { email, required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { AmplitudeEvents } from "@src/contracts/AmplitudeEvents";
import { DomainConfigurationKey, MonitoringServiceKey } from "@src/injections";

export default defineComponent({
  name: "Spei",
  props: {
    altPayData: Object as PropType<CreateAltPayOrderResponse>,
    email: {
      type: String,
      required: false,
    },
    terminalName: String,
  },
  components: {
    Card,
    TextField,
    CopySmallIcon,
    Icon,
    TelegramIcon,
    LeftArrowIcon,
    Button,
    Alert,
  },
  emits: ["onBack", "onSuccessSpei"],
  setup(props, { emit }) {
    const runtimeConfiguration = inject(DomainConfigurationKey);
    const altPayApi = inject<AltPayApi>("AltPayApi");
    const isShowAlert = ref(true);
    const isEmailInput = ref(!props.email);
    const isEmailError = ref(false);
    const altPayData = props.altPayData as CreateAltPayOrderResponse;
    const notification = inject<NotificationService>("NotificationService");
    const appService = inject("AppService") as AppService;
    const monitoring = inject(MonitoringServiceKey);
    const sessionStorageService = inject<SessionStorageService>(
      "SessionStorageService"
    ) as SessionStorageService;
    const locale = appService.getOrderOptions().cultureName;
    const expiredDate = altPayData.Model?.ExtensionData?.ExpiredDate
      ? new Date(altPayData.Model?.ExtensionData?.ExpiredDate)
      : null;

    const form = reactive<Record<string, string>>({
      clabe: altPayData?.Model?.ExtensionData?.Clabe as string,
      bank: "STP",
      // в widget есть currency a tyt?
      amount: getFormattedAmount(
        altPayData?.Model?.Amount as number,
        runtimeConfiguration?.amountSettings?.firstDelimiter,
        runtimeConfiguration?.amountSettings?.secondDelimiter,
        false
      ),
      paymentConcept: props.terminalName as string,
      email: props.email || "",
    });

    const formRules = reactive({
      clabe: {},
      bank: {},
      amount: {},
      paymentConcept: {},
      email: { required, email },
    });

    const v$ = useVuelidate(formRules, form as any);

    const isValid = () => {
      v$.value.$validate();
      return !v$.value.email.$error;
    };

    let speiWaitPaymentTask: IterableTask<TransactionResultStatus>;

    onMounted(() => {
      if (form.email && !sessionStorageService.get("isPaymentDetailsSent")) {
        sentPaymentDetails();
      }

      appService.setSpeiAlertType("process");
      speiWaitPaymentTask = createIterateWaitStatus();
      const paymentTimer = new Timer(() => {
        speiWaitPaymentTask.terminate();
        isShowAlert.value = false;
        appService.setSpeiAlertType(null);
      }, 1200000);
      speiWaitPaymentTask
        .then((result) => {
          appService.setSpeiAlertType(null);
          if (result === TransactionResultStatus.Success) {
            onSuccess();
          }
        })
        .catch((e) => {
          Logger.LogError(e);
        });
    });

    onUnmounted(() => {
      speiWaitPaymentTask.terminate();
    });

    async function sentPaymentDetails() {
      monitoring?.sendAmplitudeEvent(AmplitudeEvents.SentPaymentDetails);
      if (!isValid()) {
        Logger.LogError("invalid email");
        isEmailInput.value = true;
        return;
      }

      const sentPaymentDetailsResponse = await altPayApi
        ?.sentPaymentDetails({
          transactionId: altPayData.Model?.TransactionId as number,
          terminalPublicId: appService.getOrderOptions().terminalPublicId,
          email: form.email,
        })
        .catch((e) => {
          Logger.LogError(e);
          isEmailError.value = true;
          isEmailInput.value = true;
        });
      if (sentPaymentDetailsResponse?.Success) {
        isEmailInput.value = false;
        sessionStorageService.set("isPaymentDetailsSent", true);
      } else {
        isEmailError.value = true;
        isEmailInput.value = true;
      }
    }

    function createIterateWaitStatus() {
      return new IterableTask(
        () =>
          altPayApi!.waitAltPayTransaction({
            terminalPublicId: appService.getOrderOptions().terminalPublicId,
            transactionId: altPayData.Model?.TransactionId,
          }),
        (result) => {
          return result == TransactionResultStatus.Wait;
        }
      );
    }

    function copyValueByFieldKey(key: string) {
      clipboard.copy(form[key]);
      notification?.openNotification({
        type: NotificationType.Success,
        title: "notifications.valueCopied",
      });
    }

    function onBack() {
      monitoring?.sendAmplitudeEvent(AmplitudeEvents.ChangeMethod, {
        method: AmplitudeEvents.PaySpei,
      });
      appService.setSpeiAlertType(null);
      emit("onBack");
    }

    function onSuccess() {
      appService.setSpeiAlertType("success");
      emit("onSuccessSpei");
    }

    return {
      isValid,
      form,
      isEmailInput,
      isShowAlert,
      isEmailError,
      copyValueByFieldKey,
      onBack,
      sentPaymentDetails,
      localizedExpiredDate: expiredDate?.toLocaleDateString(locale, {
        day: "numeric",
        month: "long",
      }),
      localizedExpiredTime: expiredDate?.toLocaleTimeString(locale, {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      }),
      v$,
      formRules,
    };
  },
});
</script>

<style src="./Spei.scss" lang="scss" scoped></style>
