import {
  AppClient,
  OrdersAPI_DTO_API_Requests_GetQrStatusRequest,
} from "@src/services/api";
import {
  CreateAltPayResponse,
  QRResponse,
  QRStatusCode,
  QRStatusResponse,
} from "@cloudpayments/api-client";
import { DomainConfiguration } from "@src/runtime-configuration/DomainConfiguration";
import { SentPaymentDetailsRequest } from "@src/services/api/models/SentPaymentDetailsRequest";
import { SentPaymentDetailsResponse } from "@src/services/api/models/SentPaymentDetailsResponse";
import { TransactionResultStatus } from "@src/contracts/TransactionResultStatus";
import { CreateAltPayOrderRequest } from "@src/services/api/models/CreateAltPayOrderRequest";

export class AltPayApi {
  private readonly appClient: AppClient;
  constructor(public readonly runtimeConfiguration: DomainConfiguration) {
    this.appClient = new AppClient({
      BASE: this.runtimeConfiguration.api.host,
    });
  }

  public async createAltPayTransaction(
    request: CreateAltPayOrderRequest
  ): Promise<CreateAltPayResponse> {
    return this.appClient.order.createAltPayTransaction(request);
  }

  public async sentPaymentDetails(
    request: SentPaymentDetailsRequest
  ): Promise<SentPaymentDetailsResponse> {
    return this.appClient.order.sentPaymentDetails(request);
  }

  public async waitAltPayTransaction(
    request: OrdersAPI_DTO_API_Requests_GetQrStatusRequest
  ): Promise<TransactionResultStatus> {
    const waitPaymentStatusResponse: QRResponse<QRStatusResponse> =
      await this.appClient.order.postOrderWaitQrStatus(request);

    const transactionNotFoundErrorMessage = "Транзакция не найдена";

    if (
      waitPaymentStatusResponse &&
      waitPaymentStatusResponse?.Message &&
      waitPaymentStatusResponse.Message.includes(
        transactionNotFoundErrorMessage
      )
    ) {
      return TransactionResultStatus.Fail;
    }

    switch (waitPaymentStatusResponse.Model.StatusCode) {
      case QRStatusCode.Completed:
      case QRStatusCode.Authorized:
        return TransactionResultStatus.Success;
      case QRStatusCode.Cancelled:
      case QRStatusCode.Declined:
        return TransactionResultStatus.Fail;
      case QRStatusCode.Created:
      case QRStatusCode.Pending:
      default:
        return TransactionResultStatus.Wait;
    }
  }
}
