<div class="sbp">
    <div class="action">
        <Button type="primary" @click="onSbpPay" background="#231E45"
                :disabled="stateLoading.loadingApp"
                :loading="stateLoading.loadingApp"
        >
            <div class="pay">
                <div class="icon">
                    <Icon width="59" height="34">
                        <SbpIcon />
                    </Icon>
                </div>
            </div>
        </Button>
    </div>
    <div class="mobile bar">
        <BottomBar @onClose="onModalClose" v-if="showBottomBar">
            <template v-if="!showBanks">
                <div class="bottom">
                    <div class="title">
                        <div class="icon">
                            <Icon width="78" height="46">
                                <Sbp2Icon />
                            </Icon>
                        </div>
                        <div class="description">
                            <Text type="caption">{{ $t('components.sbp.selectBank') }}</Text>
                        </div>
                    </div>
                    <div class="action">
                        <Button type="primary" @click="generateSbpLink">{{ $t('app.process.errorButton') }}</Button>
                    </div>
                    <div class="attention">
                        <div class="icon">
                            <Icon width="16" height="16">
                                <AttentionIcon/>
                            </Icon>
                        </div>
                        <div class="description">
                            <Text type="caption">{{ $t('components.sbp.paymentError') }}</Text>
                        </div>
                    </div>
                </div>
            </template>
            <template v-if="showBanks">
                <div class="bottom">
                    <div class="title">
                        <div class="icon">
                            <Icon width="78" height="46">
                                <Sbp2Icon />
                            </Icon>
                        </div>
                        <div class="description">
                            <Text type="caption">{{ $t('components.sbp.selectBank') }}</Text>
                            <div class="action">
                                <Button :disabled="stateLoading.checkPayRequest" type="secondary" class="repeat" @click="checkPayStatus">
                                    <div class="icon" v-if="stateLoading.checkPayRequest">
<!--                                        <LoaderNewIcon />-->
                                    </div>
                                    <div class="text">{{ $t('components.sbp.repeat') }}</div>
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div class="search-field">
                        <TextField
                                class="search-text-field"
                                label="Поиск банка"
                                v-model="textForSearch"
                        >
                            <template v-slot:iconsLeft>
                                <Icon width="20" height="20">
                                    <SearchIcon/>
                                </Icon>
                            </template>
                        </TextField>
                    </div>
                    <div class="banks">
                        <template v-for="bank of filteredBanks">
                            <a
                                :href="bank.isWebClientActive ?
                                    (
                                        (bank.webClientUrl && bank.webClientUrl.startsWith('https:')) ?
                                            `${bank.webClientUrl}/${providerQrId}` :
                                            `https://${bank.webClientUrl}/${providerQrId}`
                                    ) : `${bank.schema}${qrLink}`"
                                @click="afterOpenBank(true); sendSbpStatistic(bank.bankName)" class="bank" target="_blank"
                            >
                                <div class="line"></div>
                                <div class="item">
                                    <div class="icon">
                                        <img class="image" :src="bank.logoURL" :alt="bank.bankName">
                                    </div>
                                    <div class="description">
                                        <Text type="p2">
                                            {{ bank.bankName }}
                                        </Text>
                                    </div>
                                </div>
                            </a>
                        </template>
                    </div>
                </div>
            </template>
        </BottomBar>
    </div>
</div>
