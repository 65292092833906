/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { UAParser } from "ua-parser-js";
import type { OrdersAPI_DTO_API_Requests_GetQrStatusRequest } from "@src/services/api";
import type { OrdersAPI_DTO_API_Requests_OrderPayRequest } from "@src/services/api";
import type { OrdersAPI_DTO_API_Requests_QrPayRequest } from "@src/services/api";

import type { CancelablePromise } from "@src/services/api";
import { isWebView } from "@src/common/Utils";
import { DeviceDetector } from "@cloudpayments/vue-utils";
import type { BaseHttpRequest } from "@src/services/api";
import { CreateAltPayOrderRequest } from "../models/CreateAltPayOrderRequest";
import { SentPaymentDetailsRequest } from "@src/services/api/models/SentPaymentDetailsRequest";
import { SentPaymentDetailsResponse } from "@src/services/api/models/SentPaymentDetailsResponse";
import { CreateAltPayResponse } from "@cloudpayments/api-client";

export class OrderService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @param publicOrderId
   * @returns any Success
   * @throws ApiError
   */
  public getOrder(publicOrderId?: string): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "GET",
      url: "/order",
      query: {
        publicOrderId: publicOrderId,
      },
    });
  }

  /**
   * @param requestBody
   * @returns any Success
   * @throws ApiError
   */
  public postOrderPay(
    requestBody?: OrdersAPI_DTO_API_Requests_OrderPayRequest
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "POST",
      url: "/order/pay",
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * @param requestBody
   * @returns any Success
   * @throws ApiError
   */
  public postOrderSbpQrImage(
    requestBody?: OrdersAPI_DTO_API_Requests_QrPayRequest
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "POST",
      url: "/order/sbp_qr_image",
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * @param requestBody
   * @returns any Success
   * @throws ApiError
   */
  public postOrderSbpQrLink(
    requestBody?: OrdersAPI_DTO_API_Requests_QrPayRequest
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "POST",
      url: "/order/sbp_qr_link",
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * @param requestBody
   * @returns any Success
   * @throws ApiError
   */
  public postOrderTinkoffPayQrImage(
    requestBody?: OrdersAPI_DTO_API_Requests_QrPayRequest
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "POST",
      url: "/order/tinkoffpay_qr_image",
      body: { ...requestBody, ...this.getUserAgentParams() },
      mediaType: "application/json",
    });
  }

  /**
   * @param requestBody
   * @returns any Success
   * @throws ApiError
   */
  public postOrderTinkoffPayQrLink(
    requestBody?: OrdersAPI_DTO_API_Requests_QrPayRequest
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "POST",
      url: "/order/tinkoffpay_qr_link",
      body: { ...requestBody, ...this.getUserAgentParams() },
      mediaType: "application/json",
    });
  }

  public postOrderSberPayQrImage(
    requestBody?: OrdersAPI_DTO_API_Requests_QrPayRequest
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "POST",
      url: "/order/sberpay_qr_image",
      body: { ...requestBody, ...this.getUserAgentParams() },
      mediaType: "application/json",
    });
  }

  /**
   * @param requestBody
   * @returns any Success
   * @throws ApiError
   */
  public postOrderSberPayQrLink(
    requestBody?: OrdersAPI_DTO_API_Requests_QrPayRequest
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "POST",
      url: "/order/sberpay_qr_link",
      body: { ...requestBody, ...this.getUserAgentParams() },
      mediaType: "application/json",
    });
  }

  /**
   * @param requestBody
   * @returns any Success
   * @throws ApiError
   */
  public postOrderGetQrStatus(
    requestBody?: OrdersAPI_DTO_API_Requests_GetQrStatusRequest
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "POST",
      url: "/order/get_qr_status",
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * @param requestBody
   * @returns any Success
   * @throws ApiError
   */
  public postOrderWaitQrStatus(
    requestBody?: OrdersAPI_DTO_API_Requests_GetQrStatusRequest
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "POST",
      url: "/order/wait_qr_status",
      body: requestBody,
      mediaType: "application/json",
    });
  }

  public createAltPayTransaction(
    request: CreateAltPayOrderRequest
  ): Promise<CreateAltPayResponse> {
    return this.httpRequest.request({
      method: "POST",
      url: "/order/altpay",
      body: { ...request, ...this.getUserAgentParams() },
      mediaType: "application/json",
    });
  }

  private getUserAgentParams() {
    const { browser, os } = UAParser(navigator.userAgent);
    const deviceDetector = new DeviceDetector();
    return {
      os: os.name,
      webview: isWebView(),
      device: isWebView()
        ? "MobileApp"
        : deviceDetector.isMobile
        ? "Mobile"
        : "DesktopWeb",
      browser: browser.name,
    };
  }

  public sentPaymentDetails(
    request: SentPaymentDetailsRequest
  ): Promise<SentPaymentDetailsResponse> {
    return this.httpRequest.request({
      method: "POST",
      url: "/order/payment_details",
      body: request,
      mediaType: "application/json",
    });
  }
}
