import {
  AltPayType,
  BinInfoResponse,
  PayResponse,
  StartApplePaySessionResponse,
} from "@cloudpayments/api-client";

import { CardData } from "@cloudpayments/vue-ui-presets";

import { Checkout, CheckoutSettings } from "@cloudpayments/checkout-script";

import { environment } from "@src/environment";

import { isInstagramWebView, Logger, Utils } from "@cloudpayments/vue-utils";
import { ApiClient } from "@src/services/ApiClient";
import { ApplePayApi } from "@src/services/externalApiServices/ApplePay/ApplePayApi";
import { GooglePayApi } from "@src/services/externalApiServices/GooglePay/GooglePayApi";
import {
  TinkoffInstallmentApi,
  TinkoffResponse,
} from "@src/services/externalApiServices/TinkoffPay/TinkoffInstallmentApi";
import { OrderOptions } from "@src/contracts/OrderOptions";
import {
  CardFields,
  ValidationError,
} from "@cloudpayments/checkout-script/lib/types/models";
import { CreateAltPayOrderResponse } from "@src/services/api/models/CreateAltPayOrderResponse";
import { CreateAltPayOrderRequest } from "@src/services/api/models/CreateAltPayOrderRequest";
import { DomainConfiguration } from "@src/runtime-configuration/DomainConfiguration";

declare let YaPay: any;

export interface IPayData {
  amount: number;
  orderNumber: string;
  publicId: string;
  currency: string;
  isOrder: boolean;
  requireEmail: boolean;
}

export class PaymentService {
  private readonly publicId: string;

  public readonly googlePayApi: GooglePayApi;

  constructor(
    private apiClient: ApiClient,
    publicId: string,
    private readonly runtimeConfiguration: DomainConfiguration
  ) {
    this.publicId = publicId;
    this.googlePayApi = new GooglePayApi(runtimeConfiguration);
  }

  public getBinInfo(
    bin: string,
    IsCheckCard?: boolean,
    TerminalPublicId?: string
  ): Promise<BinInfoResponse> {
    return this.apiClient.binInfo(bin, IsCheckCard, TerminalPublicId);
  }

  private getCardCryptogram = async (
    cardData: CardData,
    validators?: Partial<
      Record<CardFields, (...args: any[]) => ValidationError | null>
    >,
    metadata?: Record<string, unknown>
  ) => {
    const _checkout = await this.getCheckout(validators);
    return await _checkout.createPaymentCryptogram(
      {
        cvv: cardData.cardSecretCode,
        cardNumber: cardData.cardNumber,
        expDateMonthYear: cardData.cardExpiration,
      },
      metadata
    );
  };

  public pay(
    isOrder: boolean
  ): (
    publicSubscriptionId: string,
    cryptogram: string,
    email?: string | null,
    saveCard?: boolean,
    term?: number
  ) => Promise<PayResponse> {
    return isOrder
      ? this.apiClient.orderPay.bind(this.apiClient)
      : this.apiClient.subscriptionPay.bind(this.apiClient);
  }

  private processApplePaySessionAndPay = async (
    terminalUrl: string,
    options: IPayData,
    label?: string,
    saveCard?: boolean
  ): Promise<PayResponse> => {
    Logger.LogInfo("processApplePaySessionAndPay");

    await ApplePayApi.isApplePayAvailable();

    const applePaySession = ApplePayApi.getApplePaySession(
      terminalUrl,
      options.amount,
      options.currency,
      options.requireEmail ?? false,
      label
    );

    Logger.LogInfo("applePaySession created", applePaySession);

    return new Promise<PayResponse>((resolve, reject) => {
      applePaySession.oncancel = (e) => {
        Logger.LogInfo("applePaySession canceled", e);
        reject(e);
      };

      applePaySession.onvalidatemerchant = (data) => {
        Logger.LogInfo("applePaySession onvalidatemerchant", data);

        const request = {
          ValidationUrl: data.validationURL,
          TerminalPublicId: options.publicId,
          SharedAppleMerchantIdUrl: Utils.getHostnameFromUrl(
            window.location.href
          )!,
        };

        Logger.LogInfo("applePaySession startApplePaySession request", request);

        this.apiClient
          .startApplePaySession(request)
          .then((validationResult: StartApplePaySessionResponse) => {
            Logger.LogInfo(
              "applePaySession startApplePaySession api response",
              validationResult
            );
            applePaySession.completeMerchantValidation(validationResult.Model);
          });
      };

      applePaySession.onpaymentauthorized = (authorizedEvent) => {
        Logger.LogInfo("applePaySession onpaymentauthorized", authorizedEvent);
        const token = authorizedEvent.payment.token;
        this.pay(options.isOrder)(
          options.orderNumber,
          JSON.stringify(token),
          undefined,
          saveCard
        ).then((result) => {
          applePaySession.completePayment(
            result.Success
              ? ApplePaySession.STATUS_SUCCESS
              : ApplePaySession.STATUS_FAILURE
          );
          resolve(result);
        });
      };

      applePaySession.begin();
    });
  };

  private processGooglePay = async (
    paymentOptions: IPayData,
    allowedPaymentMethods: string[],
    saveCard?: boolean
  ): Promise<PayResponse> => {
    Logger.LogInfo("Запуск GooglePay");

    const googlePaymentRequest = this.googlePayApi.getGooglePaymentDataRequest(
      paymentOptions.publicId,
      paymentOptions.amount,
      paymentOptions.currency,
      allowedPaymentMethods,
      paymentOptions.requireEmail,
      window.location.origin
    );
    Logger.LogInfo("googlePaymentRequest", googlePaymentRequest);
    const client = await this.googlePayApi.getPaymentsClient();
    const paymentData = await client.loadPaymentData(googlePaymentRequest);

    Logger.LogInfo("GPay PaymentData", paymentData);

    try {
      return await this.pay(paymentOptions.isOrder)(
        paymentOptions.orderNumber,
        JSON.stringify(paymentData.paymentMethodData.tokenizationData.token),
        undefined,
        saveCard
      );
    } catch {
      return new Promise((resolve) => {
        resolve({
          Success: false,
        } as PayResponse);
      }) as Promise<PayResponse>;
    }
  };

  async payByAltPay(
    options: OrderOptions,
    altPayType: AltPayType,
    saveCard?: boolean
  ): Promise<CreateAltPayOrderResponse> {
    const altPayRequest: CreateAltPayOrderRequest = {
      publicOrderId: options.publicOrderId,
      altPayType,
      email: options.email,
      SuccessRedirectUrl: options.successRedirectUrl,
      FailRedirectUrl: options.failRedirectUrl,
    };

    const fallbackRedirectUrl =
      options?.successRedirectUrl || options.terminalInfo.TerminalUrl;

    if (altPayType === AltPayType.Som) {
      altPayRequest.SaveCard = saveCard;

      if (!isInstagramWebView()) {
        altPayRequest.SuccessRedirectUrl = `${environment.orders.baseUrl}${environment.orders.resultPage}?Success=true&fallbackRedirect=${fallbackRedirectUrl}`;
        altPayRequest.FailRedirectUrl = `${environment.orders.baseUrl}${environment.orders.resultPage}?Success=false&fallbackRedirect=${fallbackRedirectUrl}`;
      } else {
        altPayRequest.FailRedirectUrl = window.location.href;
      }
    }

    return await this.apiClient.createAltPay(altPayRequest);
  }

  async payByApplePay(
    paymentOptions: IPayData,
    saveCard?: boolean
  ): Promise<PayResponse> {
    return await this.processApplePaySessionAndPay(
      window.location.origin,
      paymentOptions,
      undefined,
      saveCard
    );
  }

  async payByGooglePay(
    paymentOptions: IPayData,
    allowedPaymentMethods: string[],
    saveCard?: boolean
  ): Promise<PayResponse> {
    return await this.processGooglePay(
      paymentOptions,
      allowedPaymentMethods,
      saveCard
    );
  }

  payByTinkoffInstallment(
    paymentOptions: OrderOptions,
    promoCode: string,
    transactionId?: string,
    isCredit?: boolean
  ): Promise<PayResponse> {
    return new Promise((resolve, reject) => {
      const tinkoffRequest =
        TinkoffInstallmentApi.createTinkoffInstallmentRequest(
          {
            invoiceId: `${paymentOptions.publicOrderId}`,
            amount: paymentOptions.amount,
            email: paymentOptions.email,
            description: paymentOptions.description,
            orderNumber: transactionId,
          },
          paymentOptions.terminalInfo,
          promoCode,
          isCredit
        );
      if (!tinkoffRequest) {
        reject();
      }
      TinkoffInstallmentApi.getSession().then((tinkoffSession) => {
        if (!tinkoffSession) {
          reject();
        }
        if (!paymentOptions.terminalInfo.IsTest && tinkoffRequest) {
          tinkoffSession.create(tinkoffRequest);
        } else if (tinkoffRequest) {
          tinkoffRequest.demoFlow = "sms";
          tinkoffSession.createDemo(tinkoffRequest);
        } else {
          reject();
        }

        const onMessageHandler = (data: TinkoffResponse) => {
          switch (data.type) {
            case tinkoffSession.constants.SUCCESS:
              resolve({
                Success: true,
              } as PayResponse);
              break;
            case tinkoffSession.constants.APPOINTED:
              resolve({
                Success: true,
              } as PayResponse);
              break;
            case tinkoffSession.constants.REJECT:
              resolve({
                Success: false,
              } as PayResponse);
              break;
            case tinkoffSession.constants.CANCEL:
              resolve({
                Success: false,
              } as PayResponse);
              break;
            case tinkoffSession.constants.ERROR_RESUME:
              resolve({
                Success: false,
              } as PayResponse);
              break;
            default:
              return;
          }
          tinkoffSession.methods.off(
            tinkoffSession.constants.SUCCESS,
            onMessageHandler
          );
          tinkoffSession.methods.off(
            tinkoffSession.constants.APPOINTED,
            onMessageHandler
          );
          tinkoffSession.methods.off(
            tinkoffSession.constants.REJECT,
            onMessageHandler
          );
          tinkoffSession.methods.off(
            tinkoffSession.constants.CANCEL,
            onMessageHandler
          );
          tinkoffSession.methods.off(
            tinkoffSession.constants.ERROR_RESUME,
            onMessageHandler
          );
          if (data?.meta?.iframe?.destroyAll) {
            data.meta.iframe.destroyAll();
          }
        };

        tinkoffSession.methods.on(
          tinkoffSession.constants.SUCCESS,
          onMessageHandler
        );
        tinkoffSession.methods.on(
          tinkoffSession.constants.APPOINTED,
          onMessageHandler
        );
        tinkoffSession.methods.on(
          tinkoffSession.constants.REJECT,
          onMessageHandler
        );
        tinkoffSession.methods.on(
          tinkoffSession.constants.CANCEL,
          onMessageHandler
        );
        tinkoffSession.methods.on(
          tinkoffSession.constants.ERROR_RESUME,
          onMessageHandler
        );
        tinkoffSession.methods.on(
          tinkoffSession.constants.READY,
          onMessageHandler
        );
        tinkoffSession.methods.on(
          tinkoffSession.constants.KEEP_ALIVE,
          onMessageHandler
        );
      });
    });
  }

  async payByCard(
    publicOrderId: string,
    cardData: CardData,
    isOrder: boolean,
    email: string | null = null,
    saveCard?: boolean,
    validators?: Partial<
      Record<CardFields, (...args: any[]) => ValidationError | null>
    >,
    metadata?: Record<string, unknown>,
    term?: number
  ): Promise<PayResponse> {
    const cryptogram = await this.getCardCryptogram(
      cardData,
      validators,
      metadata
    );
    try {
      return await this.pay(isOrder)(
        publicOrderId,
        cryptogram,
        email,
        saveCard,
        term
      );
    } catch (e: any) {
      throw new Error(e);
    }
  }

  public async getCheckout(
    validators?: Partial<
      Record<CardFields, (...args: any[]) => ValidationError | null>
    > | null
  ) {
    const checkoutSettings: CheckoutSettings = {
      publicId: this.publicId,
      key: {
        pem: this.runtimeConfiguration.publicKey.pem,
        version: this.runtimeConfiguration.publicKey.version,
      },
    };

    if (validators) {
      checkoutSettings.validators = validators;
    }
    return new Checkout(checkoutSettings);
  }
}
