<template src="./WaitBottomSheet.html"></template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import {
  Button,
  CloseIcon,
  Icon,
  LoaderIcon,
  ModalService,
  Text,
} from "@cloudpayments/vue-ui-kit";
import ReloadIcon from "@src/icons/ReloadIcon.vue";

export default defineComponent({
  name: "WaitBottomSheet",
  components: {
    Icon,
    Button,
    CloseIcon,
    Text,
    LoaderIcon,
    ReloadIcon,
  },
  props: {
    icon: {
      type: Object,
    },
    reloadStatus: {
      type: Function,
    },
    isSom: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    // TODO: Перепилить сервис модалок на нормальную инъекцию и сделать context per modal
    const modal = inject<ModalService>("ModalService");
    const backToPaymentMethods = () => {
      modal!.closeModal({ status: "canceled" });
    };
    const onReloadStatus = () => {
      if (props.reloadStatus) {
        props.reloadStatus();
      }
    };
    return {
      backToPaymentMethods,
      onReloadStatus,
    };
  },
});
</script>

<style src="./WaitBottomSheet.scss" lang="scss" scoped></style>
