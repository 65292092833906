export enum PaymentMethodsStatist {
  Card = "Card",
  ApplePay = "APay",
  GooglePay = "GPay",
  MasterPass = "MP",
  YandexPay = "YPay",
  TinkoffInstallmentPay = "TinkoffInstallment",
  SbpPay = "SbpPay",
  TinkoffPay = "TinkoffPay",
  MirPay = "MirPay",
  SPEI = "SPEI",
  BNPL = "BNPL",
  SOM = "SOM",
  CASH = "Cash",
  TinkoffCredit = "TinkoffCredit",
}
