<template src="./OrderHeader.html"></template>
<script lang="ts">
import {
  computed,
  defineComponent,
  getCurrentInstance,
  inject,
  PropType,
} from "vue";
import {
  Alert,
  Button,
  Card,
  Header,
  LoaderService,
  Text,
} from "@cloudpayments/vue-ui-kit";
import { SubscriptionHelper } from "@src/common/subscription";
import { OrderOptions } from "@src/contracts/OrderOptions";
import { Utils } from "@cloudpayments/vue-utils";
import { AppService } from "@src/services/AppService";
import { DomainConfigurationKey } from "@src/injections";
import AdvertiseBanner from "@src/components/AdvertiseBanner.vue";
import { PaymentMethodEnum } from "@src/contracts/PaymentForm";
import { PaymentMethodsStatist } from "@src/enums/PaymentMethodsStatist";
import { TransactionInfoResponseModel } from "@cloudpayments/api-client";

export default defineComponent({
  name: "OrderHeader",
  components: {
    Card,
    Header,
    Text,
    Button,
    Alert,
    AdvertiseBanner,
  },
  props: {
    title: String,
    successPaymentMethod: String,
    failPaymentMethod: String,
    showOrderId: Boolean,
    isCharity: Boolean,
    order: Object as PropType<OrderOptions>,
    transactionInfo: Object as PropType<TransactionInfoResponseModel>,
  },
  setup(props) {
    const loader = inject<LoaderService>("LoaderService");
    const appService = inject("AppService") as AppService;
    const runtimeConfiguration = inject(DomainConfigurationKey);
    const bannerUrl = runtimeConfiguration?.giftBannerRedirectUrl;

    let formattedAmount = Utils.getFormattedAmountWithCurrency({
      amount: props.order?.amount as number,
      currencyCode: props.order?.currency,
      firstDelimiter: runtimeConfiguration?.amountSettings?.firstDelimiter,
      secondDelimiter: runtimeConfiguration?.amountSettings?.secondDelimiter,
      locale: runtimeConfiguration?.amountSettings?.locale,
    });

    const subscription = computed(() => {
      return props.order?.subscription;
    });

    const isOrder = computed(() => {
      return props.order?.isOrder;
    });

    const app = getCurrentInstance();
    const vueLang = app?.appContext?.config?.globalProperties;

    const installmentData = computed(() => {
      return props.transactionInfo?.InstallmentData
        ? `${Utils.getFormattedAmountWithCurrency({
            amount: props.transactionInfo?.InstallmentData?.MonthlyPayment,
            currencyCode: appService?.getOrderOptions()?.currency as string,
            firstDelimiter:
              runtimeConfiguration?.amountSettings?.firstDelimiter,
            secondDelimiter:
              runtimeConfiguration?.amountSettings?.secondDelimiter,
            locale: runtimeConfiguration?.amountSettings?.locale,
          })} x ${props.transactionInfo?.InstallmentData?.Term} ${vueLang?.$t(
            "app.process.cardInstallment.interestFreeMonths"
          )}`
        : "";
    });

    const periodIntervalText = computed(() => {
      const app = getCurrentInstance();

      if (!app || !subscription.value) {
        return "";
      }

      return SubscriptionHelper.getInterval(
        subscription.value.recurrentInterval,
        subscription.value.period,
        app.appContext.config.globalProperties
      );
    });

    const speiAlertDataType = appService.getSpeiAlertType();

    const paymentMethodStatist = computed(() => {
      switch (props.successPaymentMethod as PaymentMethodEnum) {
        case PaymentMethodEnum.Card:
          return PaymentMethodsStatist.Card;
        case PaymentMethodEnum.YandexPay:
          return PaymentMethodsStatist.YandexPay;
        case PaymentMethodEnum.ApplePay:
          return PaymentMethodsStatist.ApplePay;
        case PaymentMethodEnum.GooglePay:
          return PaymentMethodsStatist.GooglePay;
        case PaymentMethodEnum.Tinkoff:
          return PaymentMethodsStatist.TinkoffPay;
        case PaymentMethodEnum.SBP:
          return PaymentMethodsStatist.SbpPay;
        case PaymentMethodEnum.TinkoffPay:
          return PaymentMethodsStatist.TinkoffPay;
        case PaymentMethodEnum.Spei:
          return PaymentMethodsStatist.SPEI;
        case PaymentMethodEnum.Som:
          return PaymentMethodsStatist.SOM;
        case PaymentMethodEnum.Dolyame:
          return PaymentMethodsStatist.BNPL;
        default:
          return props.successPaymentMethod;
      }
    });

    function backToSite(url: string) {
      const params = new URLSearchParams(
        props.order?.getBackOptions()
      ).toString();
      const backUrl = !url.includes("?")
        ? `${url}?${params}`
        : `${url}&${params}`;
      window.location.replace(backUrl);
    }

    return {
      loader,
      subscription,
      isOrder,
      periodIntervalText,
      formattedAmount,
      backToSite,
      speiAlertDataType,
      paymentMethodStatist,
      bannerUrl,
      installmentData,
    };
  },
});
</script>
<style src="./OrderHeader.scss" scoped lang="scss"></style>
