/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum KkmServiceDto_Common_StampType {
    '_2' = 2,
    '_3' = 3,
    '_5' = 5,
    '_1520' = 1520,
}
