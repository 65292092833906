import { defineAsyncComponent } from "vue";
// Cards Icons

export const SpeiIcon = defineAsyncComponent(() => import("./spei-icon.vue"));

export const CopySmallIcon = defineAsyncComponent(
  () => import("./CopySmallIcon.vue")
);

export const TelegramIcon = defineAsyncComponent(
  () => import("./TelegramIcon.vue")
);

export const LeftArrowIcon = defineAsyncComponent(
  () => import("./LeftArrowIcon.vue")
);

export const DownBlueIcon = defineAsyncComponent(
  () => import("./DownBlueIcon.vue")
);

export const SearchIcon = defineAsyncComponent(
  () => import("./SearchIcon.vue")
);
