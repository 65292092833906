import {
  AppClient,
  OrdersAPI_DTO_API_Requests_GetQrStatusRequest,
  OrdersAPI_DTO_API_Requests_QrPayRequest,
} from "@src/services/api";
import {
  QRImageResponse,
  QRLinkResponse,
  QRResponse,
  QRStatusCode,
  QRStatusResponse,
} from "@cloudpayments/api-client";
import { DomainConfiguration } from "@src/runtime-configuration/DomainConfiguration";
import { TransactionResultStatus } from "@src/contracts/TransactionResultStatus";

export class TinkoffPayApi {
  private readonly appClient: AppClient;
  constructor(public readonly runtimeConfiguration: DomainConfiguration) {
    this.appClient = new AppClient({
      BASE: this.runtimeConfiguration.api.host,
    });
  }

  public async createTinkoffPayQrImage(
    request: OrdersAPI_DTO_API_Requests_QrPayRequest
  ): Promise<QRResponse<QRImageResponse>> {
    return this.appClient.order.postOrderTinkoffPayQrImage(request);
  }

  public async createTinkoffPayQrLink(
    request: OrdersAPI_DTO_API_Requests_QrPayRequest
  ): Promise<QRResponse<QRLinkResponse>> {
    return this.appClient.order.postOrderTinkoffPayQrLink(request);
  }

  public async getQrStatus(
    request: OrdersAPI_DTO_API_Requests_GetQrStatusRequest
  ): Promise<QRResponse<QRStatusResponse>> {
    return this.appClient.order.postOrderGetQrStatus(request);
  }

  public async waitTinkoffPayStatus(
    request: OrdersAPI_DTO_API_Requests_GetQrStatusRequest
  ): Promise<any> {
    const waitPaymentStatusResponse: QRResponse<QRStatusResponse> =
      await this.appClient.order.postOrderWaitQrStatus(request);

    const transactionNotFoundErrorMessage = "Транзакция не найдена";

    if (
      !waitPaymentStatusResponse.Success ||
      (waitPaymentStatusResponse &&
        waitPaymentStatusResponse?.Message &&
        waitPaymentStatusResponse.Message.includes(
          transactionNotFoundErrorMessage
        ))
    ) {
      return TransactionResultStatus.Fail;
    }

    switch (waitPaymentStatusResponse.Model.StatusCode) {
      case QRStatusCode.Completed:
      case QRStatusCode.Authorized:
        return TransactionResultStatus.Success;
      case QRStatusCode.Cancelled:
      case QRStatusCode.Declined:
        return TransactionResultStatus.Fail;
      case QRStatusCode.Created:
      case QRStatusCode.Pending:
      default:
        return TransactionResultStatus.Wait;
    }
  }
}
