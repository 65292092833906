import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

import Home from "@src/views/Home/Home.vue";
import Subscription from "@src/views/Subscription/Subscription.vue";
import { loadLocaleMessages } from "@src/langSetup";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/error",
    name: "ErrorPage",
    component: () => import("../views/ErrorPage/ErrorPage.vue"),
  },
  {
    path: "/d/:id",
    name: "Order",
    component: Home,
  },
  {
    path: "/s/:id",
    name: "Subscription",
    component: Subscription,
  },
  {
    path: "/s/update/:id",
    redirect: {
      name: "Subscription",
    },
  },
  {
    path: "/",
    redirect: {
      name: "ErrorPage",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  await loadLocaleMessages();
  return next();
});

export default router;
