<div class="card-form">
    <ExternalCardForm
            ref="cardForm"
            :showEmail="false"
            :currencySymbol="''"
            :formattedAmount="formattedAmount"
            :isCvvRequired="state.cvvRequired"
            :skipExpiryValidation="skipExpiryValidation"
            :cardType="state.cardType"
            @onSubmit="payByCard"
            @onChange="onChange"
            @onBankInfoChanged="$emit('onBankInfoChanged', $event)"
            @onUpdateEmail="onUpdateEmail"
            @onValid="$emit('onValid', $event)"
            :configuration="config"
            :loading="loading"
            :disabled="state.disabled"
            :isForeignCardEnabled="isForeignCardEnabled"
            :payment-button-translate-text="isCharity ? 'components.cardProcess.charity' : 'components.cardProcess.payment'"
            :direction="direction"
            :defaultCardData="defaultCardData"
            :is-payment-button-hidden="isPaymentButtonHidden"/>
</div>
