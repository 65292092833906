/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum KkmServiceDto_Common_UnitCode {
    '_0' = 0,
    '_10' = 10,
    '_11' = 11,
    '_12' = 12,
    '_20' = 20,
    '_21' = 21,
    '_22' = 22,
    '_30' = 30,
    '_31' = 31,
    '_32' = 32,
    '_40' = 40,
    '_41' = 41,
    '_42' = 42,
    '_50' = 50,
    '_51' = 51,
    '_70' = 70,
    '_71' = 71,
    '_72' = 72,
    '_73' = 73,
    '_80' = 80,
    '_81' = 81,
    '_82' = 82,
    '_83' = 83,
    '_255' = 255,
}
